<template>
    <div class="conteneur">
        <h1>Page non trouvée</h1>
    </div>
</template>

<script>
    export default {
        name: 'NotFound',
        created() {
            if (process.env.VUE_APP_DEFAULT_TOKEN) {
                this.$router.replace({name: 'dossiers', params: {token: process.env.VUE_APP_DEFAULT_TOKEN}})
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/scss/colors";

    .conteneur {
        color: white;
        background: $error;
        height: 100%;
        width: 100%;
        h1 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
