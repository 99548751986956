<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                Crédit
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire"
            >
                <div
                    id="rachat"
                    class="form-group"
                >
                    <label>Rachat</label>
                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.rachat.$model"
                    />
                </div>

                <div
                    id="titulaire_code"
                    class="form-group"
                    v-if="customValidations.titulaire_code"
                    :class="{'form-group--error': $v.$dirty && $v.schema.titulaire_code.$invalid}"
                >
                    <label> Titulaire * </label>
                    <select
                        class="form-control"
                        v-model.number="$v.schema.titulaire_code.$model"
                    >
                        <option value="">Sélectionner</option>
                        <option
                            v-for="option in formulaire.donnees.titulaires"
                            :value="option.code"
                            v-html="option.libelle"
                        >
                        </option>
                    </select>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.titulaire_code.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="libelle"
                    class="form-group"
                >
                    <label> Libelle </label>
                    <input
                        class="form-control"
                        placeholder="Libelle"
                        v-model.trim="$v.schema.libelle.$model"
                    >
                </div>

                <div
                    id="pret_type_code"
                    class="form-group"
                >
                    <label> Type de prêt </label>
                    <select
                        class="form-control"
                        v-model="$v.schema.pret_type_code.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.pretTypes"
                            :value="option.code"
                            v-html="option.code"
                        >
                        </option>
                    </select>
                </div>

                <div
                    id="preteur"
                    class="form-group"
                >
                    <label> Prêteur </label>
                    <input
                        class="form-control"
                        placeholder="Prêteur"
                        v-model.trim="$v.schema.preteur.$model"
                    >
                </div>

                <div
                    id="devise_id"
                    v-if="customValidations.devise_id"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.devise_id.$invalid}"
                >
                    <label> Devise * </label>
                    <select
                        class="form-control"
                        v-model.number="$v.schema.devise_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.devises"
                            :value="option.id"
                            v-html="option.lib"
                        >
                        </option>
                    </select>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.devise_id.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="bien_id"
                    class="form-group"
                >
                    <label> {{bien}} </label>
                    <select
                        class="form-control"
                        v-model.number="$v.schema.bien_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.donnees.biens.menu"
                            :value="option.id"
                            v-html="option.label"
                            :key="option.id"
                        >
                        </option>
                    </select>
                </div>

                <div
                    id="date_debut"
                    class="form-group"
                >
                    <label> Date de début </label>
                     <datepicker
                        input-class="form-control"
                        placeholder="Date de début"
                        :calendar-button="true"
                        calendar-button-icon="far fa-calendar-alt"
                        format="DD/MM/YYYY"
                        v-model="$v.schema.date_debut.$model"
                        :language="$utils.language"
                        initial-view="year"
                        :typeable="true"
                    ></datepicker>
                </div>

                <div
                    id="duree"
                    class="form-group"
                >
                    <label> Durée </label>
                    <input
                        class="form-control"
                        placeholder="Durée"
                        v-model.number="$v.schema.duree.$model"
                    >
                </div>

                <div
                    id="date_fin"
                    class="form-group"
                >
                    <label> Date de fin </label>
                    <datepicker
                        input-class="form-control"
                        placeholder="Date de fin"
                        :calendar-button="true"
                        calendar-button-icon="far fa-calendar-alt"
                        format="DD/MM/YYYY"
                        :language="$utils.language"
                        v-model="$v.schema.date_fin.$model"
                        initial-view="year"
                        :typeable="true"
                    ></datepicker>
                </div>

                <div
                    id="restant_mois"
                    class="form-group"
                >
                    <label> Nombre de mois restants </label>

                    <input
                        class="form-control"
                        placeholder="Nombre de mois restants"
                        v-model.number="$v.schema.restant_mois.$model"
                        readonly="readonly"
                    >
                </div>

                <div
                    id="valeur"
                    class="form-group"
                >
                    <label> Capital initial </label>
                    <input
                        class="form-control"
                        placeholder="Valeur"
                        v-model.number="$v.schema.valeur.$model"
                    >
                </div>

                <div
                    id="capital_restant"
                    class="form-group"
                >
                    <label> Capital restant </label>
                    <input
                        class="form-control"
                        placeholder="Capital restant"
                        v-model.number="$v.schema.capital_restant.$model"
                    >
                </div>

                <div
                    id="taux"
                    class="form-group"
                >
                    <label> Taux </label>
                    <input
                        class="form-control"
                        placeholder="Taux"
                        v-model.number="$v.schema.taux.$model"
                    >
                </div>

                <div
                    id="mensualite_actuelle"
                    class="form-group"
                >
                    <label> Mensualité actuelle </label>
                    <input
                        class="form-control"
                        placeholder="Mensualité actuelle"
                        v-model.number="$v.schema.mensualite_actuelle.$model"
                    >
                </div>

                <div
                    id="assurance"
                    class="form-group"
                >
                    <label> Assurance </label>
                    <input
                        class="form-control"
                        placeholder="Assurance"
                        v-model.number="$v.schema.assurance.$model"
                    >
                </div>

                <div
                    id="date_palier2"
                    class="form-group"
                >
                    <label> Date de palier 2 </label>
                    <datepicker
                        input-class="form-control"
                        placeholder="Date de palier 2"
                        :calendar-button="true"
                        calendar-button-icon="far fa-calendar-alt"
                        format="DD/MM/YYYY"
                        :language="$utils.language"
                        v-model="$v.schema.date_palier2.$model"
                        initial-view="month"
                        :typeable="true"
                    ></datepicker>
                </div>

                <div
                    id="mensualite_palier2"
                    class="form-group"
                >
                    <label> Mensualité palier 2 </label>
                    <input
                        class="form-control"
                        placeholder="Mensualité palier 2"
                        v-model.number="$v.schema.mensualite_palier2.$model"
                    >
                </div>

                <div
                    id="date_palier3"
                    class="form-group"
                >
                    <label> Date de palier 3 </label>
                    <datepicker
                        input-class="form-control"
                        placeholder="Date de palier 3"
                        :calendar-button="true"
                        calendar-button-icon="far fa-calendar-alt"
                        format="DD/MM/YYYY"
                        :language="$utils.language"
                        v-model="$v.schema.date_palier3.$model"
                        initial-view="month"
                        :typeable="true"
                    ></datepicker>
                </div>

                <div
                    id="mensualite_palier3"
                    class="form-group"
                >
                    <label> Mensualité palier 3 </label>
                    <input
                        class="form-control"
                        placeholder="Mensualité palier 3"
                        v-model.number="$v.schema.mensualite_palier3.$model"
                    >
                </div>

                <div
                    id="date_palier4"
                    class="form-group"
                >
                    <label> Date de palier 4 </label>
                    <datepicker
                        input-class="form-control"
                        format="DD/MM/YYYY"
                        :calendar-button="true"
                        calendar-button-icon="far fa-calendar-alt"
                        placeholder="Date de palier 4"
                        :language="$utils.language"
                        v-model="$v.schema.date_palier4.$model"
                        initial-view="month"
                        :typeable="true"
                    ></datepicker>
                </div>

                <div
                    id="mensualite_palier4"
                    class="form-group"
                >
                    <label> Mensualité palier 4 </label>
                    <input
                        class="form-control"
                        placeholder="Mensualité palier 4"
                        v-model.number="$v.schema.mensualite_palier4.$model"
                    >
                </div>

                <div
                    id="date_palier5"
                    class="form-group"
                >
                    <label> Date de palier 5 </label>
                    <datepicker
                        input-class="form-control"
                        format="DD/MM/YYYY"
                        :calendar-button="true"
                        calendar-button-icon="far fa-calendar-alt"
                        placeholder="Date de palier 5"
                        :language="$utils.language"
                        v-model="$v.schema.date_palier5.$model"
                        initial-view="month"
                        :typeable="true"
                    ></datepicker>
                </div>

                <div
                    id="mensualite_palier5"
                    class="form-group"
                >
                    <label> Mensualité palier 5 </label>
                    <input
                        class="form-control"
                        placeholder="Mensualité palier 5"
                        v-model.number="$v.schema.mensualite_palier5.$model"
                    >
                </div>

                <div
                    id="endettement_exclu"
                    class="form-group"
                >
                    <label>Hors endettement</label>
                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.endettement_exclu.$model"
                    />
                </div>

            </div>

            <div class="cadre-pied background-primary">
                <button
                    class="btn btn-transparent btn-save-next"
                    @click="e => soumettre(e, 'enregistrerEtRediriger')"
                >
                    Enregistrer +
                    <div class="etape-suivante">passer à l'étape suivante</div>
                </button>
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrer')"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>
        </div>
    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'
    import dayjs from 'dayjs'

    export default {
        name: 'Credit',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, this.metadataCreer()) : {}
            }
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                destination: ''
            }
        },
        created() {
            const {dossierId, id} = this.$route.params

            this.$formulaire.charger('commun', 'Credit', {dossierId: dossierId})
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    if (id)
                        return api.creditCharger({dossierId, creditId: id})
                            .then(d => {
                                this.schema = d.data
                                this.scrollTo(this.$route)
                            })
                })
        },
        computed: {
            bien() {
                if (this.schema.pret_type_code === "Prêt immo") {
                    return "Bien immobilier financé"
                }
                return 'Bien'
            },
            customValidations(){
                return this.formulaire.customValidations(this.schema, this.metadataCreer())
            }
        },
        methods: {
            soumettre(e, destination) {
                e.preventDefault()
                this.destination = destination
                this.valider(e)
            },
            enregistrer(next = null) {
                const methode = this.$route.params.id ? 'creditModifier' : 'creditCreer'
                return api[methode]({dossierId: this.$route.params.dossierId, credit: this.schema})
                    .then(() => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer(this.destination)
                            }
                            this.$root.$emit('revenusChargesRafraichir')
                        })
                    })
            },
            metadataCreer() {
                return {
                    dossier: this.$dossier
                }
            }
        },
        watch: {
            'schema.date_debut'(value) {
                // soit on résoud la date de fin si on connait la durée
                // Attention à savoir: on soustrait 1 à la durée car c'est le nombre d'échéances que l'on souhaite
                if (this.schema.duree) {
                    this.schema.date_fin = dayjs(value).add(this.schema.duree - 1, 'months').format('YYYY-MM-DD')
                }
            },
            'schema.duree'(value) {
                // soit on résoud la date de fin si on connait la date début
                // Attention à savoir: on soustrait 1 à la durée car c'est le nombre d'échéances que l'on souhaite
                if (this.schema.date_debut)
                    this.schema.date_fin = dayjs(this.schema.date_debut).add(value - 1, 'months').format('YYYY-MM-DD')
            },
            'schema.date_fin'(value) {
                if (this.schema.date_fin)
                    this.schema.restant_mois = parseInt(dayjs(this.schema.date_fin).diff(new Date(), 'months')) + 1
            }
        }
    }
</script>
