import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Dossier extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'civilites', cle: 'civilites'},
        {type: 'donnees', fonction: 'entitesCharger', cle: 'entites'}
    ]

    schema() {
        return {
            date_saisie: new Date(),
            emprunteur: {
                civilite_id: null,
                nom: null,
                prenom: null,
                nom_jf: null
            },
            couple: false,
            coemprunteur: {
                civilite_id: null,
                nom: null,
                prenom: null,
                nom_jf: null
            },
            possesseur_id: null
        }
    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            date_saisie: {
                required
            },
            emprunteur: {
                civilite_id: {
                    required
                },
                nom: {
                    required,
                },
                prenom: {
                    required
                },
                nom_jf: {}
            },
            couple: {},
            coemprunteur: {
                civilite_id: {
                    required: customValidations.coemprunteur.civilite_id ? required : false
                },
                nom: {
                    required: customValidations.coemprunteur.civilite_id ? required : false
                },
                prenom: {
                    required: customValidations.coemprunteur.civilite_id ? required : false
                },
                nom_jf: {}
            },
            possesseur_id: {
                required: customValidations.possesseur_id ? required : false,
            }
        }
    }

    customValidations(schema, meta) {
        const compte = meta.compte || {}
        return {
            possesseur_id: compte.niveau === "MANDANT" || compte.niveau === "LICENCIE",
            coemprunteur: {
                civilite_id: schema.couple,
                nom: schema.couple,
                prenom: schema.couple

            }
        }
    }
}
