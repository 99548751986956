import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Epargne extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'versementTypes', cle: 'versementTypes'},
        {type: 'constantes', fonction: 'devises', cle: 'devises'},
    ]

    schema() {
        return {
            id: '',
            libelle: null,
            devise_id: null,
            montant: null,
            solde_date: null,
            versement_type_id: null,
            versement_montant: null
        }

    }

    validations(schema, { dossier }) {
        return {
            id: {},
            libelle: {required},
            devise_id: {
                required: dossier.multidevise ? required : false
            },
            montant: {},
            solde_date: {},
            versement_type_id: {},
            versement_montant: {}
        }
    }

}

export class EpargnePrecisions extends Formulaire {
    schema() {
        return {
            epargne_precisions: '',
        }
    }

    validations(schema) {
        return {
            epargne_precisions: {},
        }
    }
}
