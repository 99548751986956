<template>
    <div class="etape-container">
        <div class="etape-menu"
             :class="{'menu-active': menuActive}"
        >
            <div
                class="cadre content-cadre background-gray">

                <div class="cadre-tete cadre-tete--menu">
                    <div>
                        Informations personnelles
                    </div>
                </div>

                <div
                    id="menu"
                    class="cadre-content"
                >
                    <menu-warning
                        v-if="!$dossier.representant_legal"
                        :menu="menu.menu"
                    ></menu-warning>
                    <div
                        class="menu-brique"
                        v-for="element in menu.menu"
                        :key="element.id"
                        :id="element.id"
                    >
                        <router-link
                            tag="div"
                            :to="$utils.formulaireAller('personnel', element.id)"
                            @click.native="$root.$emit('menuChanger')"
                        >

                            <a class="menu-brique-tete">
                                <div>
                                    <i class="far fa-user-circle avatar-icon"></i> {{element.nom}} {{element.prenom}}
                                </div>
                            </a>
                            <div class="menu-brique-contenu"
                                 v-if="element.champs">
                                <menu-kpis
                                    :kpis="element.champs"
                                    :base-route="$utils.formulaireAller('personnel', element.id)"
                                ></menu-kpis>
                            </div>
                            <div class="menu-brique-pied"
                                v-if="element.meta && element.meta.requis">
                                <completion
                                    :element="element"
                                ></completion>
                            </div>
                        </router-link>

                    </div>

                </div>
            </div>
        </div>

        <div class="etape-content">
            <transition name="fade"
                        mode="out-in">

                <router-view
                    :key="$route.path"
                    :menu-continuer="menuContinuer"
                ></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
    import api from '@/api'
    import { menuMixin } from '@/utils/formulaire'

    export default {
        name: 'PersonnelListe',
        mixins: [
            menuMixin
        ],
        props: {
            navigation: Array
        },
        methods: {
            charger() {
                const {dossierId} = this.$route.params
                return api.emprunteursMenu({dossierId, etape: 'informations-personnelles'})
            },
            initialiser() {
                const id = this.$route.params.id ? this.$route.params.id : this.menu.menu[0].id
                this.$router.push(this.$utils.formulaireAller('personnel', id))
            },
            menuContinuer() {
                this.menuCharger().then(() => {
                    this.$router.push(this.$utils.prochaineEtapeAller('personnel', this.$route, this.navigation))
                })
            }
        }
    }

</script>
