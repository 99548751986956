import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Banque extends Formulaire {

    schema() {
        return {
            id: '',
            banque: '',
            anciennete: ''
        }

    }

    validations(schema) {
        return {
            id: {},
            banque: {required},
            anciennete: {}
        }
    }

}
