import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'


export class Partenaire extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'apporteurTypes', cle: 'apporteurTypes'},
    ]

    schema() {
        return {
            apporteur_type_id: null,
            societe: false,
            nom: '',
            prenom: '',
            raison_sociale: '',
            adresse: '',
            postal_code: '',
            ville: '',
            telephone_fixe: '',
            telephone_mobile: '',
            email: '',
            contact: {
                nom: '',
                prenom: '',
                telephone_fixe: '',
                telephone_mobile: '',
                email: '',
            }
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            apporteur_type_id: {required},
            societe: {},
            nom: {
                required: customValidations.nom ? required : false,
            },
            prenom: {
                required: customValidations.prenom ? required : false,
            },
            raison_sociale: {
                required: customValidations.raison_sociale ? required : false,
            },
            adresse: {},
            postal_code: {},
            ville: {},
            telephone_fixe: {},
            telephone_mobile: {},
            email: {
                required: customValidations.email ? required : false,
            },
            contact: {
                nom: {
                  required: customValidations.contact.nom ? required : false,
                },
                prenom: {
                  required: customValidations.contact.prenom ? required : false,
                },
                telephone_fixe: {},
                telephone_mobile: {},
                email: {
                  required: customValidations.contact.email ? required : false,
                },
            }
        }
    }

    customValidations(schema) {
        return {
            prenom: !schema.societe,
            nom: !schema.societe,
            email: !schema.societe,
            raison_sociale: schema.societe,
            contact: {
                nom: schema.societe,
                prenom: schema.societe,
                email: schema.societe

            }
        }
    }

}
