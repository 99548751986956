<template>
      <div class="modal-backdrop">
          <div class="modal">
            <header class="modal-header">
                <slot name="header">
                    <button class="btn   btn-file"
                        @click="fermerWebViewer()" 
                    >                   
                    <i class="far fa-eye-close icon-custom icons" ></i>             
                    </button>
                </slot>
            </header>
            <slot name="body">
                <div>
                <WebViewer :path="`${publicPath}webviewer`" :url= "this.fichierUrl"/>    
                </div>             
            </slot>   
          </div>
      </div>
  </template>
  
<script>
    import WebViewer from '../components/WebViewer.vue'
    export default {
        name: 'ModalEspaceClient',
        components:{WebViewer},
        props:{  
            fichierUrl:String
        },
        data(){
            return{
                publicPath: process.env.BASE_URL   
            }
        },
        methods : {
            fermerWebViewer(){
                this.$root.$emit('fermerWebViewer')
            }
        }
    }
</script>
  
  <style lang="scss" scoped>
   @import "../assets/scss/_colors.scss"; 
    .modal-backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(103, 143, 177, 0.562);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .modal {
      background: #eeeeee;
      width: 50% !important;
      height: 70% !important;
      box-shadow: 2px 2px 20px 1px;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      border-radius: 5px !important;
    }
    
    .modal-header{
      padding: 15px;
      background: #305ab5;
      display: flex;
      border-radius: 5px !important;
    }
    
    .modal-header {
      border-bottom: 1px solid #eeeeee;
      font-size: 30px;
      color:#305ab5;
      justify-content: center;
    }
    .modal-body {
      position: relative;
      font-size: 30px;
      align-self: center;
      padding: 20px 10px;
    }
    .close {
      border-color: #305ab5;
      font-size: 30px;
      margin-left: 20px;
      cursor: pointer;
      font-weight: bold;
      color: #305ab5;
      background: transparent;
    }
    .btn {
        min-width: 50px;
        margin-bottom: 5px;
        background-color:#ffffff;
        height: 40px;
        border-radius: 2px;
        padding: 0 20px;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.2s;
        }
    .btn:hover {
        background-color: $primary;

    }
    .btn-file {
        position: relative;
        overflow: hidden;
        border: 1px solid #305ab5;
        display: inline-block;
    }
    .icon-custom{
        color: #305ab5;
        font-size: 20px;    
    }
     .icons:hover { 
        color: aliceblue;
    }
    .fa-eye-close:before {
        content: "\F070 ";
    }
    @media only screen and (max-width: 767px) {
        
        .modal {
            width: 100% !important;
            height: 100% !important;
        }
        
    }


  </style>
  