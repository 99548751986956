import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Identite extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'civilites', cle: 'civilites'},
    ]

    schema () {
        return {
            civilite_id: null,
            nom: '',
            prenom: '',
            nom_jf: ''
        }
    }

    validations (schema) {
        return {
            civilite_id: {
                required
            },
            nom: {
                required
            },
            prenom: {
                required
            },
            nom_jf: {}
        }
    }

}
