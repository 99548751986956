<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                Présentation projet
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire">

                <div
                    id="presentation_sous_titre"
                    class="form-group"
                >

                    <label> Sous-titre du projet </label>

                    <input
                        maxlength="255"
                        class="form-control"
                        v-model="$v.schema.presentation_sous_titre.$model"
                        placeholder="Présentation"
                    >

                </div>

                <div
                    id="coordonne_cacher"
                    class="form-group"
                    v-if="!$dossier.professionnel"
                >
                    <label> Présentation </label>

                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.presentation.$model"
                    />
                </div>

                <div
                    id="presentation_texte"
                    class="form-group"
                    v-if="$v.schema.presentation.$model"
                    :class="{'form-group--error': $v.$dirty && $v.schema.presentation_texte.$invalid}"
                >

                    <label> Texte de présentation * </label>

                    <textarea
                        class="form-control"
                        placeholder="Présentation"
                        rows="10"
                        v-model="$v.schema.presentation_texte.$model"
                    ></textarea>

                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.presentation_texte.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

            </div>

            <div class="cadre-pied background-primary">
                <button
                    type="submit"
                    class="btn btn-transparent"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>
        </div>
    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'PresentationProjet',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema) : {}
            }
        },
        data() {
            return {
                formulaire: null,
                schema: {}
            }
        },
        created() {
            let params = {'dossierId': this.$route.params.dossierId}

            this.$formulaire.charger('commun', 'ConfigurationPresentationProjet', this.$route.params.dossierId)
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    api.presentationDossierCharger({dossierId: params.dossierId})
                        .then(d => {
                            this.schema = d.data
                            this.scrollTo(this.$route)
                        })
                })
        },
        methods: {
            enregistrer(next = null) {

                return api.presentationDossierModifier({dossierId: this.$route.params.dossierId, presentation: this.schema})
                    .then(() => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer()
                            }
                        })
                    })
            }
        }
    }
</script>
