<template>
    <div class="etape-container">

        <div class="etape-menu"
             :class="{'menu-active': menuActive}"
        >
            <div
                class="cadre content-cadre background-gray">

                <div class="cadre-tete cadre-tete--menu">

                    Revenus

                </div>

                <div
                    id="menu"
                    class="cadre-content"
                >
                    <div
                        v-for="element in menu.menu"
                        :key="element.id"
                        class="menu-brique"
                    >

                        <router-link
                            tag="div"
                            :to="$utils.formulaireAller('revenu', element.id)"
                            @click.native="$root.$emit('menuChanger')"
                        >

                            <a class="menu-brique-tete">
                                <div>
                                    <i class="far fa-user-circle avatar-icon"></i>
                                    {{ element.label }}
                                </div>
                            </a>
                            <div class="menu-brique-contenu"
                                 v-if="element.champs && element.champs.length">
                                <menu-kpis
                                    :kpis="element.champs"
                                    :base-route="$utils.formulaireAller('revenu', element.id)"
                                ></menu-kpis>
                            </div>
                            <div class="menu-brique-pied"
                                v-if="element.meta && element.meta.requis">
                                <completion
                                    :element="element"
                                ></completion>
                            </div>

                        </router-link>

                    </div>
                </div>
            </div>
        </div>

        <div class="etape-content">
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view
                    :key="$route.path"
                    :menu-continuer="menuContinuer"
                ></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
    import api from '@/api'
    import { menuMixin } from '@/utils/formulaire'

    export default {
        name: 'RevenuMenu',
        mixins: [
            menuMixin
        ],
        props: {
            navigation: Array
        },
        methods: {
            charger() {
                const {dossierId} = this.$route.params

                return api.emprunteursMenu({dossierId, etape: 'revenus'})
            },
            initialiser() {
                const id = this.$route.params.id ? this.$route.params.id : this.menu.menu[0].id
                this.$router.push(this.$utils.formulaireAller('revenu', id))
            },
            menuContinuer() {
                this.menuCharger().then(() => {
                    this.$router.push(this.$utils.prochaineEtapeAller('revenu', this.$route, this.navigation))
                })
            }
        }
    }

</script>

<style lang="scss" scoped>
    .edit-icon {
        margin-right: 0;
        margin-left: auto;
    }
</style>
