<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                <span v-if="$dossier.professionnel">Associé</span>
                <span v-if="!$dossier.professionnel">Emprunteur</span>
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire">

                <div
                    id="situation_immobiliere_id"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.situation_immobiliere_id.$invalid}"
                    v-if="!$v.schema.$model.personne_morale"
                >
                    <label> Situation immobilière * </label>
                    <select
                        class="form-control"
                        v-model.number="$v.schema.situation_immobiliere_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option v-for="option in formulaire.constantes.situationsImmobilieres"
                                v-bind:value="option.id"
                                v-html="option.lib">
                        </option>
                    </select>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.situation_immobiliere_id.$invalid"
                    >
                        Champs invalide
                    </div>
                </div>
                <div
                    id="habite_depuis"
                    class="form-group"
                    v-if="!$v.schema.$model.personne_morale"
                >
                    <label> Habite depuis </label>
                    <input
                        class="form-control"
                        placeholder="Habite depuis"
                        v-model="$v.schema.habite_depuis.$model"
                        autocomplete="disabled"
                    />
                </div>
                <div
                    id="adresse"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.adresse.$invalid}"
                    v-if="!$v.schema.$model.personne_morale"
                >
                    <label> Adresse *
                        <div>
                            <a
                                v-if="$v.schema.id.$model != 'emprunteur'"
                                class="text-muted"
                                href="#"
                                @click="adresseReporter"
                            >
                                <small>Reporter l'adresse de l'emprunteur principal</small>
                            </a>
                        </div>
                    </label>
                    <input
                        class="form-control"
                        placeholder="Adresse"
                        v-model="$v.schema.adresse.$model"
                        autocomplete="disabled"
                    />
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.adresse.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
                <div
                    id="code_postal"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.code_postal.$invalid}"
                    v-if="!$v.schema.$model.personne_morale"
                >
                    <label> Code postal *</label>
                    <autocomplete-adresse
                        placeholderText="Code postal"
                        :value="$v.schema.code_postal.$model"
                        searchKey="postal_code"
                        :selectAction="selectAction"
                        :inputAction="(v) => $v.schema.code_postal.$model = v"
                    ></autocomplete-adresse>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.code_postal.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
                <div
                    id="ville"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.ville.$invalid}"
                    v-if="!$v.schema.$model.personne_morale"
                >
                    <label> Ville *</label>
                    <autocomplete-adresse
                        placeholderText="Ville"
                        :value="$v.schema.ville.$model"
                        searchKey="ville"
                        :selectAction="selectAction"
                        :inputAction="(v) => $v.schema.ville.$model = v"
                    ></autocomplete-adresse>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.ville.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
                <div
                    id="telephone_fixe"
                    class="form-group"
                    v-if="!$v.schema.$model.personne_morale"
                >
                    <label> Téléphone fixe </label>
                    <input
                        class="form-control"
                        placeholder="Téléphone fixe"
                        v-model="$v.schema.telephone_fixe.$model"
                        autocomplete="disabled"
                    />
                </div>
                <div
                    id="telephone_mobile"
                    class="form-group"
                >
                    <label> Téléphone mobile <i> - requis pour signature électronique</i></label>
                    <input
                        class="form-control"
                        placeholder="Téléphone mobile"
                        v-model="$v.schema.telephone_mobile.$model"
                        autocomplete="disabled"
                    />
                </div>
                <div
                    id="email"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.email.$invalid}"
                >
                    <label> Email<span style="display:none">_</span> <i> - requis pour signature électronique</i></label>
                    <input
                        class="form-control"
                        placeholder="Email"
                        v-model="$v.schema.email.$model"
                        autocomplete="disabled"
                        :disabled="emailDesactive"
                    />
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.email.$invalid"
                    >
                        Champs invalide
                    </div>
                </div>
            </div>

            <div class="cadre-pied background-primary">
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <button
                    type="submit"
                    class="btn btn-transparent"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>
        </div>
    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'Coordonne',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next) {
            this.scrollTo(to)
            next()
        },
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, {dossier: this.$dossier}) : {}
            }
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                emailDesactive: true,
            }
        },
        created() {
            const {dossierId, id} = this.$route.params
            setTimeout(() => {
                this.emailDesactive = false
            }, 500)

            this.$formulaire.charger('commun', 'Coordonne')
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    if (id)
                        api.coordonneCharger({dossierId, emprunteurId: id})
                            .then(d => {
                                this.schema = d.data
                                this.scrollTo(this.$route)
                            })
                })
        },
        methods: {
            enregistrer(next = null) {
                if (!this.$v.schema.$model.email)
                    this.$v.schema.$model.email = null

                return api.coordonneModifier({dossierId: this.$route.params.dossierId, emprunteur: this.$v.schema.$model})
                    .then(() => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer()
                            }
                        })
                    })
            },
            selectAction(localite) {
                this.$v.schema.code_postal.$model = localite.postal_code
                this.$v.schema.ville.$model = localite.ville
            },
            adresseReporter() {
                api.coordonneCharger({dossierId: this.$route.params.dossierId, emprunteurId: 'emprunteur'})
                    .then((d) => {
                        this.$v.schema.adresse.$model = d.data.adresse
                        this.$v.schema.code_postal.$model = d.data.code_postal
                        this.$v.schema.ville.$model = d.data.ville
                    })
            }
        }
    }
</script>
