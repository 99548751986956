<template>
    <div class="modal-ptz">
        <form
            @submit="valider"
            novalidate="true"
        >
            <div class="modal-content">
                <div class="cadre">
                    <div class="cadre-tete cadre-tete--menu">
                        Calcul du prêt relais
                    </div>
                    <div
                        id="formulaire"
                        class="cadre-content"
                        v-if="formulaire"
                    >
                        <div>
                            <div
                                id="mode_code"
                                class="form-group"
                                :class="{'form-group--error': $v.$dirty && $v.schema.mode_code.$error}"
                            >
                                <label>Mode de calcul *</label>
                                <select
                                    class="form-control"
                                    v-model.number="$v.schema.mode_code.$model"
                                >
                                    <option :value="null">Sélectionner</option>
                                    <option v-for="option in formulaire.constantes.pretRelaisModes"
                                            v-bind:value="option.code"
                                            v-html="option.lib">
                                    </option>
                                </select>

                                <div
                                    class="form-error"
                                    v-if="$v.$dirty && $v.schema.mode_code.$invalid"
                                >
                                    Champ invalide
                                </div>
                            </div>

                            <div
                                id="rachat"
                                v-if="customValidations.rachat"
                                class="form-group long-checkbox"
                            >
                                <div>
                                    <label> Utilisation </label>
                                    <small>
                                        Rachête le(s) prêt(s) en cours
                                    </small>
                                </div>

                                <toggle-button
                                    :labels="{checked: 'Oui', unchecked: 'Non'}"
                                    :sync="true"
                                    :width="65"
                                    :height="30"
                                    v-model="$v.schema.rachat.$model"
                                />
                            </div>

                            <div
                                class="form-group"
                            >
                                <label>Pourcentage retenu par la banque</label>
                                <input
                                    class="form-control"
                                    type="number"
                                    placeholder="Pourcentage"
                                    v-model.trim="$v.schema.pourcentage_retenu.$model"
                                    autocomplete="disabled"
                                >
                            </div>

                            <div
                                class="form-group"
                            >
                                <label>Bien à vendre</label>
                                <select
                                    class="form-control"
                                    v-model.number="$v.schema.bien_id.$model"
                                    @change="bienChanger"
                                >
                                    <option :value="null">Sélectionner</option>
                                    <option v-for="option in formulaire.donnees.biens"
                                            v-bind:value="option.id"
                                            v-html="option.label">
                                    </option>
                                </select>
                            </div>

                            <div
                                class="form-group"
                            >
                                <label>Valeur du bien en vente</label>
                                <input
                                    class="form-control"
                                    type="number"
                                    placeholder="Montant"
                                    v-model.trim="$v.schema.bien_montant.$model"
                                    autocomplete="disabled"
                                >
                            </div>

                            <div
                                class="form-group"
                            >
                                <label>CRD</label>
                                <input
                                    class="form-control"
                                    type="number"
                                    placeholder="Montant"
                                    v-model.trim="$v.schema.crd.$model"
                                    autocomplete="disabled"
                                >
                            </div>

                        </div>
                    </div>
                    <div class="cadre-pied background-primary">
                        <button
                            class="btn btn-transparent"
                            @click="annuler"
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            class="btn btn-transparent btn-ptz"
                        >
                            Calculer
                        </button>
                    </div>
                </div>
            </div>

        </form>

        <confirm
            status="success"
            confirmText="Ok"
            v-if="confirmAfficher"
            :confirmClick="appliquerClick"
        >
            <div>
                Vos modalités de prêt relais sont les suivantes:

                <ul>
                    <li>Montant du prêt relais:
                        <vue-numeric
                            currency=""
                            emptyValue="0"
                            :precision="2"
                            :readOnly="true"
                            thousandSeparator=" "
                            :value="pretRelaisDonnees.montant || 0"
                            currencySymbolPosition="suffix"
                        />
                        {{$dossier.financement_devise.code | deviseFinancement($compte.societe_devise)}}
                    </li>
                    <li>Apport généré par le prêt relais:
                        <vue-numeric
                            v-id="pretRelaisDonnees.apport > 0"
                            currency=""
                            emptyValue="0"
                            :precision="2"
                            :readOnly="true"
                            thousandSeparator=" "
                            :value="pretRelaisDonnees.apport || 0"
                            currencySymbolPosition="suffix"
                        />
                        {{$dossier.financement_devise.code | deviseFinancement($compte.societe_devise)}}
                    </li>

                    <!--<li>Montant retenu:
                        <vue-numeric
                            currency=""
                            emptyValue="0"
                            :precision="2"
                            :readOnly="true"
                            thousandSeparator=" "
                            :value="pretRelaisDonnees.montant_retenu"
                            currencySymbolPosition="suffix"
                        />
                        {{$dossier.financement_devise.code | deviseFinancement}}
                    </li>-->
                </ul>
            </div>
        </confirm>

        <alerte
            v-if="erreursAfficher"
            type="erreur"
            :fermer="() => erreursAfficher = false"
        >
            <ul>
                <li v-for="info in erreurInformations">
                    {{info}}
                </li>
            </ul>
        </alerte>

    </div>

</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'
    import Confirm from "../../../../components/Confirm.vue";
    import Alerte from "../../../../components/Alerte.vue";

    export default {
        components: {
            Alerte,
            Confirm
        },
        name: 'PretRelais',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, this.metadataCreer()) : {}
            }
        },
        props: {
            calculer: Function,
            annuler: Function
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                pretRelaisDonnees: {},
                confirmAfficher: false,
                erreurInformations: [],
                erreursAfficher: false,
            }
        },
        created() {
            this.init(this.$route.params.dossierId)
        },
        computed: {
            customValidations() {
                return this.formulaire.customValidations(this.schema, this.metadataCreer())
            }
        },
        methods: {
            init(dossierId) {
                this.$formulaire.charger('commun', 'PretRelais', {dossierId})
                    .then((formulaire) => {
                        this.formulaire = formulaire
                        this.schema = formulaire.schema()

                        api.pretRelaisCharger({dossierId}).then(d => {
                            this.schema = d.data
                            if (this.schema.bien_id) {
                                this.bienChanger()
                            }
                        })
                    })
            },
            enregistrer() {
                api.pretRelaisCalculer({dossierId: this.$route.params.dossierId, pretRelais: this.schema}).then((d) => {
                    this.pretRelaisDonnees = d.data
                    this.confirmAfficher = true
                })
            },
            metadataCreer() {
                return {
                    dossier: this.$dossier,
                }
            },
            annulerClick(e) {
                e.preventDefault()
                this.confirmAfficher = false
            },
            appliquerClick(e) {
                e.preventDefault()
                this.confirmAfficher = false
                this.calculer(this.pretRelaisDonnees)
            },
            bienChanger() {
                const {dossierId} = this.$route.params
                if (this.$v.schema.bien_id.$model) {
                    api.pretRelaisModifier({dossierId, bien_id: this.$v.schema.bien_id.$model}).then(d => {
                        if (d) {
                            this.$v.schema.bien_montant.$model = d.data.bien_montant
                            this.$v.schema.crd.$model = d.data.crd
                        }
                    }).catch((err) => {
                        if (err) {
                            const { data: { erreurs } } = err.response
                            this.erreursAfficher = true
                            this.erreurInformations = erreurs.informations
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../assets/scss/variables";

    .modal-ptz {
        .btn-ptz {
            margin-left: auto;
            margin-right: 0;
        }

        .cadre-content {
            display: flex;
            height: calc(100vh - #{$cadre-tete-height} - #{$cadre-pied-height} - 60px);

        }
        .long-checkbox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
                width: 80%;
            }
        }
    }
</style>
