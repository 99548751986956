<template>
    <div class="dossier-client-app">
        <router-view
            :dossierCharge="() => dossierCharge = true"
        ></router-view>
    </div>
</template>

<script>
    import api from '@/api'
    export default {
        name: 'App',
        data(){
          return {
            dossierCharge: false
          }
        },
        created(){
          api.configurer(this.$token)   
            this.$router.push({
              name: 'documentsCommerciaux',
              params: {
                dossierId: this.$dossierId,
                token: this.$token,
              }
            })
          
        },
    }
</script>

<style lang="scss" scoped>

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 200px;
        height: 200px;
    }

    .dossier-client-app {
        font-family: Roboto, sans-serif;
        margin: 0;
        padding: 0;
        overflow: hidden;
        height: 100%;
        color: #555;
        font-weight: 400;
        position: fixed;
        top: 0;
        width: 100%;

        * {
            box-sizing: border-box !important;
        }
    }
</style>
