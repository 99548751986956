<template>
    <div>                        
        <div v-if="!chargement" v-bind:class="((documentStatut == 'VID')||(documentStatut == 'NCO'))? 'couleur1':(documentStatut == 'AVF')? 'couleur2':'couleur3'">
            {{afficherStatut(documentStatut)}}
        </div>  
        <div v-if="chargement" class="load">
            <div class="loader"></div>
            en cours d'importation
        </div>           
    </div>
</template>

<script>
export default {
    name:"DocumentStatut",
    props:{
        documentStatut:String,
        documentId:String
    },
    data(){
        return{
            chargement:false
        }
    },
    created(){
        this.$root.$on('chargement',(chargement,docId)=>{
            if(this.documentId == docId){
                this.chargement=chargement
            }      
        })
    },
    methods:{
        //on check les différents statuts des document pour définir l'affichage textuelle du statut           
        afficherStatut(documentStatut){
            let statut="" ;             
            switch (documentStatut){
                case 'AVF':
                    statut="À vérifier";
                    break;
                case 'VID':
                    statut="Manquant"
                    break;
                    case 'NCO':
                    statut="Non-Conforme"
                    break;
                case 'COF':
                    statut = "Conforme"
                    break;
                default:
                    statut="";
                    break;
            }                
            return statut
        }
    }
}

</script>

<style lang="scss" scoped>
    
    .load{
        display: flex;
        text-align: center;
        
    }
    .loader {
        border: 11px solid #f3f3f3;
        border-radius: 50%;
        border-top: 11px solid #3498db;
        width: 39px;
        height: 39px;
        -webkit-animation: spin 1s linear infinite; /* Safari */
        animation: spin 1s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
    .couleur1{
        color:crimson
    }
     .couleur2{
        color:blue
    }
     .couleur3{
        color:forestgreen
    }
    @media only screen and (max-width: 767px) {
        
        .loader{
            width: 24px;
            height: 22px;
        }
        
    }
    

</style>