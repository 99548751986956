import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Bien extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'projetUsages', cle: 'projetUsages'},
        {type: 'constantes', fonction: 'garantieTypes', cle: 'garantieTypes'},
        {type: 'constantes', fonction: 'devises', cle: 'devises'},
        {type: 'donnees', fonction: 'titulairesLister', cle: 'titulaires'},
    ]

    schema () {
        return {
            titulaire_code: null,
            projet_usage_id: null,
            devise_id: null,
            lieu: '',
            description: '',
            achat_date: '',
            achat_prix: 0,
            valeur: 0,
            garantie_type_id: null,
            loyer: 0,
        }
    }

    validations (schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            titulaire_code: {
                required: customValidations.titulaire_code ? required : false,
            },
            projet_usage_id: {
                required
            },
            devise_id: {
                required: customValidations.devise_id ? required : false,
            },
            lieu: {},
            description: {},
            achat_date: {},
            achat_prix: {},
            valeur: {},
            garantie_type_id: {},
            loyer: {},
        }
    }

    customValidations(schema, meta){
        const { dossier } = meta
        return {
            titulaire_code: dossier.professionnel || dossier.emprunteurs_total > 2,
            devise_id: dossier.multidevise
        }
    }

}

export class BienPrecisions extends Formulaire {
    schema() {
        return {
            bien_precisions: '',
        }
    }

    validations(schema) {
        return {
            bien_precisions: {},
        }
    }
}
