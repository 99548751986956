import Vue from 'vue'

Vue.filter('afficherBoolean', function (value) {
    if (!value) return 'Non'
    return 'Oui'
})

Vue.filter('deviseFinancement', function (value, override) {
    if (override)
        return override

    const devises = {
        EUR: '€',
        CHF: 'CHF'
    }
    return devises[value] || value
})


