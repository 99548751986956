import VueScrollTo from 'vue-scrollto'

const menuMixin = {
    data() {
        return {
            menu: [],
            menuActive: true
        }
    },
    created() {
        if (this.charger) {
            this.charger()
                .then(d => {
                    this.menu = d.data

                    try {
                        if(window.innerWidth > 768){
                          this.initialiser()
                        }
                    }
                    catch (error) {
                        console.log('Initialiser : fonction requise')
                        console.log(error)
                    }
                })
        }
        this.$root.$on('menuCharger', () => {
            this.charger()
                .then(d => {
                    this.menu = d.data
                })
        })

        this.$root.$on('menuChanger', (v) => {
          this.menuActive = v !== undefined ? v : !this.menuActive
        })
    },
    computed:{
        menuFiltre(){
          if(this.menu && this.menu.menu){
            return this.menu.menu.filter(m => m.id !== 'precisions')
          }
          return []
        }
    },
    watch: {
        '$route'() {
            setTimeout(() => {
                VueScrollTo.scrollTo('#menu .router-link-active', 500, {container: '#menu'})
            }, 0)
        }
    },
    methods: {
        menuCharger() {
            this.$root.$emit('navigationCharger')
            return this.charger()
                .then(d => {
                    this.menu = d.data
                    this.menuActive = true
                })
        }
    }
}

const formulaireMixin = {
    beforeRouteUpdate(to, from, next) {
        this.confirmModal(next)
    },
    beforeRouteLeave(to, from, next) {
        this.confirmModal(next)
    },
    props: {
        menuContinuer: Function
    },
    updated() {
        this.$root.$emit('formulaireCharge')
    },
    data() {
        return {
            notification: {
                afficher: false,
                texte: 'Formulaire enregistré'
            },
            enregistrement: {
                afficher: false,
                texte: 'Enregistrement en cours'
            }
        }
    },
    methods: {
        scrollTo(route) {
            if (!route.query) {
                return
            }
            const champs = Object.keys(route.query)
            if (champs && champs.length) {
                setTimeout(() => {
                    VueScrollTo.scrollTo(`#${champs[0]}`, 500, {container: '#formulaire'})
                    const $el = document.querySelector(`#${champs[0]} input, #${champs[0]} select, #${champs[0]} .multiselect`)
                    if ($el) {
                        $el.focus()
                    }
                }, 500)
            }
        },

        valider: function (e, next, conteneur = '#formulaire') {
            e.preventDefault()
            this.$v.$touch()

            if (this.$v.$anyError) {
                this.$utils.erreurAfficher(this.$v.schema, conteneur)
                return false
            }
            
            
            try {
                this.$v.$reset()

                if (this.enregistrement.afficher)
                    return;

                this.enregistrement.afficher = true
                this.$root.$emit('enregistrementAfficher', true)

                this.enregistrer(next).then(() => {
                  this.enregistrement.afficher = false
                  this.$root.$emit('enregistrementAfficher', false)
                })

                setTimeout(() => {
                  this.enregistrement.afficher = false
                  this.$root.$emit('enregistrementAfficher', false)
                }, 15000);

                if (next) {
                    next()
                }
                return true;
            }
            catch (error) {
                console.log('Enregistrer : fonction requise')
                console.log(error)
            }
        },

        notificationAfficher: function (texte) {
            this.notification.afficher = true

            if (texte)
                this.notification.texte = texte

            return new Promise((res) => {
                setTimeout(() => {
                    this.notification.afficher = false
                    res()
                }, 1000)
            })
        },

        nouveau: function (formulaire) {
            this.schema = this.formulaire.schema()
            this.$v.$reset()
            this.$router.push({name: formulaire})
        },

        confirmModal: function (next) {
            if (this.$v.$anyDirty) {
                this.$root.$emit('quitForm', {
                    annuler: () => {
                        this.$v.$reset()
                        next()
                    },
                    confirmer: () => {
                        this.valider({
                            preventDefault() {
                            }
                        }, next)
                    }
                })
            } else {
                next()
            }
        },

        formulaireAnnuler(){
          this.confirmModal(() => {
            this.$root.$emit('menuChanger')
          })
        },

        schemaInitialiser(data) {
            Object.keys(data).forEach(k => {
                this.schema[k] = data[k]
            })
        }
    }
}

export { menuMixin, formulaireMixin }
