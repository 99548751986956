import Vue from 'vue'

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        document.body.addEventListener('click', function (event) {
            const dropdownOpenBtn = Array.from(event.target.classList).indexOf('dropdown-open') > -1
            if (dropdownOpenBtn) {
                return
            }
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        })
    }
})

Vue.directive('tooltip', {
    bind: function (el, binding, vnode) {

    }
})

Vue.directive('float', {
    update: function (el, binding, vnode) {
        el.value = el.value.replace(",", ".");
    }
});
