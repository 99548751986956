<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                Banque
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire"
            >
                <div
                    id="banque"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.banques.$invalid}"
                >
                    <label> Liste des banques * </label>
                    <textarea
                        class="form-control"
                        placeholder="Liste des banques"
                        rows="10"
                        v-model="$v.schema.banques.$model"
                    ></textarea>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.banques.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
            </div>

            <div class="cadre-pied background-primary">
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <button
                    type="submit"
                    class="btn btn-transparent"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>
        </div>
    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'Banque',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema) : {}
            }
        },
        data() {
            return {
                formulaire: null,
                schema: {}
            }
        },
        created() {
            const {dossierId, id} = this.$route.params

            this.$formulaire.charger('professionnel', 'Banque')
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    if (id)
                        api.banquesCharger({dossierId})
                            .then(d => {
                                this.schema = d.data
                                this.scrollTo(this.$route)
                            })
                })
        },
        methods: {
            enregistrer(next = null) {

                return api.banquesModifier({dossierId: this.$route.params.dossierId, banques: this.$v.schema.$model})
                    .then(() => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer()
                            }
                        })
                    })
            }
        }
    }
</script>
