import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Assurance extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'sports', cle: 'sports'},
    ]

    schema () {
        return {
            id: '',
            assurance_active: false,
            fumeur: false,
            taille: '',
            poids: '',
            professionnel_deplacements: false,
            professionnel_hauteur: false,
            professionnel_partiel: false,
            professionnel_manutention: false,
            sport_ids: null,
        }
    }

    validations (schema) {
        return {
            id: {},
            assurance_active: {},
            fumeur: {},
            taille: {},
            poids: {},
            professionnel_deplacements: {},
            professionnel_hauteur: {},
            professionnel_partiel: {},
            professionnel_manutention: {},
            sport_ids: {
                required : schema.assurance_active ? required : false
            }
        }
    }

}

export class AssurancePrecisions extends Formulaire {
    schema() {
        return {
            delegation_assurance: null,
        }
    }

    validations(schema) {
        return {
            delegation_assurance: {}
        }
    }
}
