import { fr } from 'vuejs-datepicker/dist/locale'
import VueScrollTo from 'vue-scrollto'

export const Utils = {

    install(Vue, options) {

        Vue.prototype.$utils = {

            language: fr,

            erreurAfficher(formulaire, conteneurId) {
                const champs = Object.keys(formulaire.$model)
                const erreur = champs.find((champ) => {
                    if (!formulaire[champ])
                        return false

                    return formulaire[champ].$invalid
                })


                if (erreur && formulaire[erreur].$each && formulaire[erreur].$model instanceof Array) {
                    const erreurId = Object.values(formulaire[erreur].$each)
                        .findIndex((e) => e.$invalid)

                    VueScrollTo.scrollTo(`#${erreur}_${erreurId}`, 500, {container: conteneurId})
                    return
                }

                if (erreur) {
                    VueScrollTo.scrollTo(`#${erreur}`, 500, {container: conteneurId})
                }

            },

            convertirTableau(arr, cle) {
                return arr.reduce((acc, curr) => Object.assign({}, acc, {[curr[cle]]: curr}), {})
            },

            outilsDeCalculAfficher(url) {
                return /cout-hors-frais|frais-annexes|avances-et-deduction/.test(url)
            },

            metriquesAfficher(url, dossier) {
                if (!dossier.professionnel && /(revenu|charge|bien|epargne)\/.*/.test(url)) {
                    return /(revenu|charge|bien|epargne)/.exec(url)[1]
                }
                return null
            },

            formulaireAller(etape, id) {
                let etapes = {
                    identite: {
                        'defaut': 'identiteModifier'
                    },
                    personnel: {
                        'defaut': 'personnelModifier',
                    },
                    coordonne: {
                        'defaut': 'coordonneModifier'
                    },
                    professionnel: {
                        'defaut': 'professionnelModifier'
                    },
                    banque: {
                        'defaut': 'banqueModifier'
                    },
                    assurance: {
                        'defaut': 'assuranceModifier'
                    },
                    revenu: {
                        'defaut': 'revenuModifier'
                    },
                    epargne: {
                        'creer': 'epargneCreer',
                        'defaut': 'epargneModifier'
                    },
                    bien: {
                        'creer': 'bienCreer',
                        'defaut': 'bienModifier'
                    },
                    societe: {
                        'personne_morale': 'personneMoraleModifier',
                        'adresse': 'adresseModifier',
                        'presentation': 'presentationDossierModifier'
                    },
                    societes: {
                        'creer': 'societeCreer',
                        'defaut': 'societeModifier'
                    },
                    charge: {
                      'divers': 'diversModifier',
                      'endettement': 'endettementLocatifModifier',
                      'creer': 'creditCreer',
                      'defaut': 'creditModifier'
                    },
                }

                if (!etapes[etape])
                    return

                let formulaire = {
                    name: etapes[etape]['defaut'],
                    params: {id}
                }

                if (etapes[etape][id]) {
                    formulaire.name = etapes[etape][id]
                    formulaire.params = {}
                }

                return formulaire
            },

            chercherElement(elements, _id) {
                return elements.find(({id}) => id === _id)
            },

            prochaineEtapeAller(etape, route, navigation) {
                const crudEtapes = ['identite', 'bien', 'epargne', 'societes', 'charge', 'bilan']
                const menu = navigation.find(e => e.id === etape).menu
                const routeElements = route.path.split('/')
                const routeId = routeElements[routeElements.length - 1]
                const indexRoute = menu.findIndex(r => r.id === routeId)
                const prochaineRoute = menu[indexRoute + 1]
                if (crudEtapes.includes(etape)) {
                    //return this.formulaireAller(etape, 'creer')
                }
                if(!prochaineRoute){
                    return this.prochaineEtapeNavigationAller(etape, navigation)
                }
                return this.formulaireAller(etape, prochaineRoute.id)
            },

            prochaineEtapeNavigationAller(etape, navigation) {
                const etapeIdx = navigation.findIndex((e) => e.id === etape)
                let prochaineEtape = navigation[etapeIdx + 1]
                if (!prochaineEtape) {
                    prochaineEtape = navigation[0]
                }
                let etapeMenu = null
                if(prochaineEtape.menu[0]){
                  etapeMenu = prochaineEtape.menu[0].id
                }
                if(etapeMenu){
                  return this.formulaireAller(prochaineEtape.id, etapeMenu)
                }
            }
        }
    }
}

export const Dossier = {

    install(Vue, options) {

        Vue.prototype.$dossier = {

            champsModifier(champs) {
                Object.keys(champs).forEach((k) => this[k] = champs[k])
            }
        }
    }
}

export const Compte = {
    install(Vue, options) {

        Vue.prototype.$compte = {

            champsModifier(champs) {
                Object.keys(champs).forEach((k) => this[k] = champs[k])
            }
        }
    }
}

export default Utils
