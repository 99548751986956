import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Bilan extends Formulaire {

    meta = [
        {type: 'donnees', fonction: 'titulairesLister', cle: 'titulaires'},
        {type: 'constantes', fonction: 'devises', cle: 'devises'}
    ]

    schema() {
        return {
            titulaire_code: '',
            societe_nom: '',
            devise_id: null,
            annee: null,
            societe_ca: null,
            societe_revenus: null,
            societe_charges: null,
            societe_resultat: null,
            societe_ebe: null
        }
    }

    validations(schema, {dossier}) {
        return {
            titulaire_code: {
                required: !dossier.professionnel ? required : false,
            },
            societe_nom: {
                required
            },
            devise_id: {
                required: dossier.multidevise ? required : false,
            },
            annee: {
                required
            },
            societe_ca: {
                required
            },
            societe_revenus: {
                required
            },
            societe_charges: {
                required
            },
            societe_resultat: {
                required
            },
            societe_ebe: {
                required
            }
        }
    }

}
