<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                Épargne
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire"
            >
                <div
                    id="libelle"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.libelle.$invalid}"
                >
                    <label>
                        Libelle *
                    </label>

                    <input
                        class="form-control"
                        placeholder="Libelle"
                        v-model.trim="$v.schema.libelle.$model"
                        autocomplete="disabled"
                    >

                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.libelle.$invalid"
                    >
                        Champs invalide
                    </div>
                </div>

                <div
                    id="devise_id"
                    v-if="$dossier && $dossier.multidevise"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.devise_id.$invalid}"
                >
                    <label> Devise * </label>
                    <select
                        class="form-control"
                        v-model.number="$v.schema.devise_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.devises"
                            :value="option.id"
                            v-html="option.lib"
                        >
                        </option>
                    </select>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.devise_id.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="montant"
                    class="form-group"
                >
                    <label>
                        Montant
                    </label>

                    <input
                        class="form-control"
                        placeholder="montant"
                        v-model.number="$v.schema.montant.$model"
                        autocomplete="disabled"
                    >
                </div>

                <div
                    id="solde_carte"
                    class="form-group"
                >
                    <label>
                        Solde au ...
                    </label>

                    <datepicker
                        input-class="form-control"
                        placeholder="Date"
                        :calendar-button="true"
                        calendar-button-icon="far fa-calendar-alt"
                        format="DD/MM/YYYY"
                        :language="$utils.language"
                        v-model="$v.schema.solde_date.$model"
                        initial-view="month"
                        :typeable="true"
                    ></datepicker>
                </div>

                <div
                    id="versement_type_id"
                    class="form-group"
                >
                    <label>
                        Type de versement
                    </label>

                    <select
                        class="form-control"
                        v-model.number="$v.schema.versement_type_id.$model"
                    >
                        <option value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.versementTypes"
                            :value="option.id"
                            v-html="option.lib"
                        ></option>
                    </select>
                </div>
                <div
                    id="versement_montant"
                    class="form-group"
                >
                    <label>
                        Montant du versement
                    </label>

                    <input
                        class="form-control"
                        placeholder="montant"
                        v-model.number="$v.schema.versement_montant.$model"
                        autocomplete="disabled"
                    >
                </div>
            </div>

            <div class="cadre-pied background-primary">
                <!--<button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrerEtRediriger')"
                >
                    Enregistrer +
                    <div class="etape-suivante">passer à l'étape suivante</div>
                </button>-->
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrer')"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>
        </div>

    </form>
</template>


<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'Epargne',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next) {
            this.scrollTo(to)
            next()
        },
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, {dossier: this.$dossier}) : {}
            }
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                destination: ''
            }
        },
        created() {
            const {dossierId, id} = this.$route.params

            this.$formulaire.charger('commun', 'Epargne')
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    if (id)
                        api.epargneCharger({dossierId, epargneId: id})
                            .then(d => {
                                this.schema = d.data
                                this.scrollTo(this.$route)
                            })
                })
        },
        methods: {
            soumettre(e, destination) {
                e.preventDefault()
                this.destination = destination
                this.valider(e)
            },
            enregistrer(next = null) {
                const methode = this.$route.params.id ? 'epargneModifier' : 'epargneCreer'
                return api[methode]({dossierId: this.$route.params.dossierId, epargne: this.schema})
                    .then((d) => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer(this.destination)
                            }
                            this.$root.$emit('patrimoineFinancierRafraichir')
                        })
                    })
            }
        }
    }

</script>

<style lang="scss" scoped>

</style>
