import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Banque extends Formulaire {

    schema() {
        return {
            banques: ''
        }

    }

    validations(schema) {
        return {
            banques: {required},
        }
    }

}
