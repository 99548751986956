import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Ptz extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'projetAnciennete', cle: 'projetAnciennetes'}
    ]

    schema() {
        return {
            proprietaire_nouveau: false,
            invalidite: false,
            allocation_beneficiaire: false,
            sinistre: false,
            existant_hlm: false,
            existant_travaux: false,
            projet_personnes: 0,
            projet_code_postal: '',
            projet_localite: '',
            revenu_reference: 0,
            revenu_reference_conjoint: 0,
            projet_anciennete_id: null,
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            proprietaire_nouveau: {},
            invalidite: {},
            allocation_beneficiaire: {},
            sinistre: {},
            existant_hlm: {},
            existant_travaux: {},
            projet_personnes: {
                required: customValidations.projet_personnes ? required : false,
            },
            projet_code_postal: {},
            projet_localite: {},
            revenu_reference: {required},
            revenu_reference_conjoint: {
                required: customValidations.revenu_reference_conjoint ? required : false,
            },
            projet_anciennete_id: {
                required: customValidations.projet_anciennete_id ? required : false,
            }
        }
    }

    customValidations(schema, meta) {
        const {dossier, projetAnciennetes} = meta
        const projetAnciennete = projetAnciennetes[schema.projet_anciennete_id]
        return {
            projet_personnes: !['RACH', 'RENE', 'SOUL', 'AUTR'].includes(dossier.projet_type_code),
            revenu_reference_conjoint: dossier.emprunteurs_total === 2,
            existant_hlm: projetAnciennete && projetAnciennete.code === 'ANCI',
            existant_travaux: projetAnciennete && projetAnciennete.code === 'ANCI',
            projet_anciennete_id: dossier.bien_trouve,
        }
    }
}

export class PAS extends Formulaire {

    meta = []

    schema() {
        return {
            pas_utiliser: false,
            projet_personnes: 0,
            projet_code_postal: '',
            projet_localite: '',
            revenu_reference: 0,
            revenu_reference_conjoint: 0
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            pas_utiliser: {},
            projet_personnes: {required},
            projet_code_postal: {},
            projet_localite: {},
            revenu_reference: {required},
            revenu_reference_conjoint: {
                required: customValidations.revenu_reference_conjoint ? required : false,
            }
        }
    }

    customValidations(schema, meta) {
        const {dossier} = meta
        return {
            revenu_reference_conjoint: dossier.emprunteurs_total === 2,
        }
    }
}

export class FraisNotaire extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'projetAnciennete', cle: 'projetAnciennetes'},
        {type: 'constantes', fonction: 'projetTypes', cle: 'projetTypes'},
    ]

    schema() {
        return {
            projet_type_id: null,
            projet_code_postal: '',
            projet_localite: '',
            projet_anciennete_id: null,
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            projet_type_id: {required},
            projet_code_postal: {},
            projet_localite: {},
            projet_anciennete_id: {},
        }
    }

    customValidations(schema, meta) {
        const { dossier } = meta
        return {
            projet_anciennete_id: true
        }
    }
}

export class FraisGarantie extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'garantieTypes', cle: 'garantieTypes'},
    ]

    schema() {
        return {
            garantie_type_id: null,
            garantie_montant: 0,
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            garantie_type_id: {},
            garantie_montant: {
                required: customValidations.garantie_montant ? required : false,
            },
        }
    }

    customValidations(schema, meta) {
        const garantieType = meta.garantieTypes[schema.garantie_type_id] || {}
        return {
            garantie_montant: garantieType.code === 'PPDHYPO'
        }
    }
}

export class EndettementLocatif extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'endettementLocatifTypes', cle: 'endettementTypes'},
    ]

    schema() {
        return {
            revenus_locatifs_ponderation: 0,
            endettement_locatif_id: null
        }
    }

    validations(schema) {
        return {
            revenus_locatifs_ponderation: {
                required
            },
            endettement_locatif_id: {
                required
            }
        }
    }
}

export class PretRelais extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'pretRelaisModes', cle: 'pretRelaisModes'},
        {type: 'donnees', fonction: 'biensLister', cle: 'biens'}
    ]

    schema() {
        return {
            mode_code: null,
            rachat: false,
            pourcentage_retenu: 0,
            bien_id: null,
            bien_montant: 0,
            crd: 0
        }

    }

    validations(schema, meta) {
        const customValidations = this.customValidations(schema, meta)
        return {
            mode_code: {required},
            rachat: {},
            pourcentage_retenu: {},
            bien_id: {},
            bien_montant: {},
            crd: {}
        }
    }

    customValidations(schema, meta) {
        return {
            rachat: schema.mode_code === 'VALPON'
        }
    }
}

export class PretInFine extends Formulaire {

    meta = [

    ]

    schema() {
        return {
            assurance_vie_duree: 0,
            assurance_vie_montant: 0,
            assurance_vie_taux: 0,
            assurance_vie_mensualite: 0,
            montant: 0
        }

    }

    validations(schema, meta) {
        return {
            assurance_vie_duree: {required},
            assurance_vie_montant: {required},
            assurance_vie_taux: {required},
            montant: {required},
            assurance_vie_mensualite: {}
        }
    }
}
