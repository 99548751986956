<template>
    <div
        class="dropdown-container"
        :class="{opened}"
    >
        <div class="cadre">
            <div class="cadre-tete">
                Paramètres
            </div>
            <div class="cadre-content">
                <div
                    class="dropdown-element"
                    v-for="element in elements"
                    :key="element.id"
                    @click="() => selectOption(element)"
                >
                    {{element.libelle}}
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    export default {
        name: 'Dropdown',
        props: {
            opened: Boolean,
            elements: Array,
            selectOption: Function
        }
    }
</script>

<style lang="scss">
    @import "../assets/scss/colors";

    .dropdown-container {
        user-select: none;
        min-width: 300px;
        background: white;
        z-index: 10;
        border-radius: 3px;
        position: absolute;
        display: none;
        right: -10px;
        top: 25px;
        border: 1px solid $gray-light;

        .cadre-tete {
            padding: 20px;
        }

        &::after {
            content: '';
            position: absolute;
            top: -6px;
            background: white;
            height: 10px;
            width: 10px;
            right: 10px;
            transform: rotate(45deg);
            border-left: 1px solid $gray-light;
            border-top: 1px solid $gray-light;
            z-index: -1;
        }

        &.opened {
            display: block;
        }

        .dropdown-element {

            cursor: pointer;
            padding: 10px;

            &:hover {
                color: $primary;
            }
        }

        .cadre {
            border: none;
            height: 100%;
        }

        .cadre-tete {
            color: $primary;
            border-bottom: 1px solid $primary;
        }
    }
</style>
