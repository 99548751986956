import { Dossier } from './Dossier'
import { Identite } from "./Identite"
import { PersonneMorale, Societe } from "./Societe"
import { Banque } from "./Banque"
import { BilanPrecisions } from './Bilan'
import { ProjetPrecisions } from './Projet'

export default {
    Dossier,
    Identite,
    PersonneMorale,
    Societe,
    Banque,
    BilanPrecisions,
    ProjetPrecisions
}
