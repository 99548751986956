import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class Personnel extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'situationsFamiliales', cle: 'situationsFamiliales'},
        {type: 'constantes', fonction: 'regimesMatrimoniaux', cle: 'regimesMatrimoniaux'},
    ]

    schema() {
        return {
            naissance_date: '',
            naissance_lieu: '',
            nationalite: '',
            situation_familiale_id: null,
            mariage_date: '',
            regime_matrimonial_id: null,
            enfants_nombre: '',
            enfants_ages: '',
            ci_date_validite: '',
        }
    }

    validations(schema) {
        return {
            naissance_date: {
                required
            },
            naissance_lieu: {
                required
            },
            nationalite: {
                required
            },
            situation_familiale_id: {
                required
            },
            mariage_date: {},
            regime_matrimonial_id: {
                required: schema.situation_familiale_id === 2 ? required : false
            },
            enfants_nombre: {},
            enfants_ages: {},
            ci_date_validite: {}
        }
    }

}

export class PersonnelPrecisions extends Formulaire {

    schema() {
        return {
            personnel_precisions: ''
        }
    }

    validations(schema) {
        return {
            personnel_precisions: {}
        }
    }
}
