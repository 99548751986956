<template>
    <form
        class="schema-form"
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                Associé(s) et cautionnaire(s)
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire"
            >
                <div
                    id="personne_morale"
                    class="form-group"
                >
                    <label> Personne morale </label>
                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.personne_morale.$model"
                    />
                </div>

                <div
                    v-if="$v.schema.personne_morale.$model"
                >
                    <div
                        id="raison_sociale"
                        class="form-group"
                        :class="{'form-group--error': $v.$dirty && $v.schema.raison_sociale.$invalid}">

                        <label> Raison sociale * </label>

                        <input
                            type="text"
                            class="form-control"
                            placeholder="Raison sociale"
                            v-model.trim="$v.schema.raison_sociale.$model"
                            autocomplete="disabled"
                        />

                        <div
                            class="form-error"
                            v-if="$v.$dirty && $v.schema.raison_sociale.$invalid"
                        >
                            Champs invalide
                        </div>

                    </div>
                </div>
                <div
                    v-if="!$v.schema.personne_morale.$model"
                >
                    <div
                        id="civilite_id"
                        class="form-group"
                        :class="{'form-group--error': $v.$dirty && $v.schema.civilite_id.$invalid}"
                    >
                        <label>
                            Civilité *
                        </label>

                        <select
                            class="form-control"
                            v-model.number="$v.schema.civilite_id.$model"
                        >
                            <option :value="null">Sélectionner</option>
                            <option
                                v-for="option in formulaire.constantes.civilites"
                                :value="option.id"
                                v-html="option.lib"
                            >
                            </option>
                        </select>

                        <div
                            class="form-error"
                            v-if="$v.$dirty && $v.schema.civilite_id.$invalid"
                        >
                            Champs invalide
                        </div>
                    </div>

                    <div
                        id="nom"
                        class="form-group"
                        :class="{'form-group--error': $v.$dirty && $v.schema.nom.$invalid}">

                        <label> Nom * </label>

                        <input
                            type="text"
                            class="form-control"
                            placeholder="Nom"
                            v-model.trim="$v.schema.nom.$model"
                            autocomplete="disabled"
                        />

                        <div
                            class="form-error"
                            v-if="$v.$dirty && $v.schema.nom.$invalid"
                        >
                            Champs invalide
                        </div>

                    </div>

                    <div
                        id="prenom"
                        class="form-group"
                        :class="{'form-group--error': $v.$dirty && $v.schema.prenom.$invalid}"
                    >

                        <label> Prénom * </label>

                        <input
                            type="text"
                            class="form-control"
                            placeholder="Prénom"
                            v-model.trim="$v.schema.prenom.$model"
                            autocomplete="disabled"
                        />
                        <div
                            class="form-error"
                            v-if="$v.$dirty && $v.schema.prenom.$invalid"
                        >
                            Champ invalide
                        </div>

                    </div>

                    <div
                        id="nom_jf"
                        class="form-group"
                    >

                        <label>
                            Nom de jeune fille
                        </label>

                        <input
                            type="text"
                            class="form-control"
                            placeholder="Nom de jeune fille"
                            v-model.trim="$v.schema.nom_jf.$model"
                            autocomplete="disabled"
                        />
                    </div>


                </div>
                <div
                    id="gerant"
                    class="form-group"
                >
                    <label> Gérant </label>
                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.gerant.$model"
                    />
                </div>

                <div
                    id="capital_part"
                    class="form-group"
                    v-if="customValidations.capital_part"
                    :class="{'form-group--error': $v.$dirty && $v.schema.capital_part.$invalid}"
                >
                    <label> Part du capital * </label>

                    <input
                        type="text"
                        class="form-control"
                        placeholder="Part"
                        v-model.number="$v.schema.capital_part.$model"
                        autocomplete="disabled"
                    />

                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.capital_part.$invalid"
                    >
                        Champs invalide
                    </div>

                </div>

                <div
                    id="caution_code"
                    class="form-group"
                >
                    <label>
                        Cautionnement personnel
                    </label>

                    <select
                        class="form-control"
                        v-model.number="$v.schema.caution_code.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.cautions"
                            :value="option.code"
                            v-html="option.lib"
                        >
                        </option>
                    </select>
                </div>


            </div>

            <div class="cadre-pied background-primary">
                <!--<button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrerEtRediriger')"
                >
                    Enregistrer +
                    <div class="etape-suivante">passer à l'étape suivante</div>
                </button>-->
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrer')"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>

        </div>

    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'Identite',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, this.metadataCreer()) : {}
            }
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                destination: ''
            }
        },
        created() {
            const {dossierId, id} = this.$route.params

            this.$formulaire.charger('professionnel', 'Identite', {dossierId})
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    if (id)
                        return api.emprunteurCharger({dossierId: dossierId, emprunteurId: id})
                            .then(d => {
                                this.schemaInitialiser(d.data)
                                this.scrollTo(this.$route)
                            })
                })
        },
        computed: {
            customValidations() {
                return this.formulaire.customValidations(this.schema, this.metadataCreer())
            }
        },
        methods: {
            soumettre(e, destination) {
                e.preventDefault()
                this.destination = destination
                this.valider(e)
            },
            enregistrer(next = null) {

                const methode = this.$route.params.id ? 'emprunteurModifier' : 'emprunteurCreer'
                return api[methode]({dossierId: this.$route.params.dossierId, emprunteur: this.schema})
                    .then(() => {
                        this.$root.$emit('dossierRafraichir', () => {
                            this.notificationAfficher().then(() => {
                                if (!next) {
                                    this.menuContinuer(this.destination)
                                }
                                if (methode === 'emprunteurCreer') {
                                    this.schema = this.formulaire.schema()
                                }
                            })
                        })
                    })
            },
            supprimer($event, id) {

            },
            metadataCreer() {
                return {
                    dossier: this.$dossier,
                    compte: this.$compte,
                    personneMorale: this.formulaire.donnees.personneMorale
                }
            }
        }
    }

</script>

<style lang="scss" scoped>

</style>
