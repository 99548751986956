import Vue from 'vue'
import Router from 'vue-router'
import particulier from '@/router/particulier'
import professionnel from '@/router/professionnel'
import Dossier from '@/views/Dossier'
import Auth from '@/views/Auth'
import NotFound from '@/views/NotFound'
import PiecesJustifEspace from '@/views/piecesJustificatives/PiecesJustifEspace'
import DocumentsCommerciauxEspace from '@/views/documentsCommerciaux/DocumentsCommerciauxEspace'

const contextes = {particulier, professionnel}

Vue.use(Router)

const routes = (children) => ({
  routes: [
    {
      path: '/404',
      name: '404',
      component: NotFound
    },
    {
      path: '/:token',
      component: Auth,
      name: 'auth',
      children: [
        {
          path: 'dossiers/:dossierId',
          name: 'dossier',
          component: Dossier,
          children
        },
        
        {
          path:'documents/:dossierId',
          name:"document",
          component:PiecesJustifEspace,
          children
        },

        {
          path:'documents/commerciaux/:dossierId',
          name:"documentsCommerciaux",
          component:DocumentsCommerciauxEspace,
          children

        }
      ]
    },
    {path: '*', redirect: {name: '404'}}
  ]
})

const createRouter = (children) => {
  return new Router(routes(children))
}


let router = createRouter([])

export function resetRouter($router, contexte) {
  const newRouter = new Router(routes(contextes[contexte]))
  $router.matcher = newRouter.matcher // the relevant part
}

export default router


//export default new Router({routes: routes})
