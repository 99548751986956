import { Formulaire } from '@/formulaires/Formulaire'
import { required } from 'vuelidate/lib/validators'

export class BanquePrecisions extends Formulaire {
    schema() {
        return {
            affranchissement_ira: null,
        }
    }

    validations(schema) {
        return {
            affranchissement_ira: {}
        }
    }
}
