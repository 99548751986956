<template>
    <div
        class="menu-form-complet"
        :class="{'menu-form-complet--complet': complet, 'menu-form-complet--noncomplet': !complet}"
    >
        <i class="far fa-check-circle"></i>
        <span>{{text}}</span>
    </div>
</template>

<script>
    export default {
        name: 'Completion',
        props: {
            element: Object,
        },
        computed: {
            complet() {
                return this.element.meta && this.element.meta.requis === this.element.meta.complet
            },
            text() {
                return (this.complet) ? 'Formulaire complet' : 'Formulaire incomplet'
            }
        }
    }
</script>

<style lang="scss" scoped>
    i.far {
        margin-right: 7px;
    }
</style>
