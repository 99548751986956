import formulaireInstance from './formulaires';

const Formulaires = {

    install(Vue, options) {

        Vue.prototype.$formulaire = {
            formulaires: {
                particulier: {},
                professionnel: {},
                commun: {}
            },

            charger: function (type, id, params) {
                if (!this.formulaires[type][id])
                    this.formulaires[type][id] = formulaireInstance(type, id)

                return this.formulaires[type][id].charger(params)
            }
        }
    }
}

export default Formulaires
