<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                <span v-if="$dossier.professionnel">Associé</span>
                <span v-if="!$dossier.professionnel">Emprunteur</span>
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>

            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire">

                <div
                    id="categorie_professionnelle_ids"
                    class="form-group"
                    v-bind:class="{'form-group--error': $v.$dirty && $v.schema.categorie_professionnelle_ids.$invalid}"
                >
                    <label> Categories professionnelles * </label>
                    <multiselect
                        :value="$v.schema.categorie_professionnelle_ids.$model"
                        :options="formulaire.constantes.categoriesProfessionnelles"
                        :select-action="(ids) => $v.schema.categorie_professionnelle_ids.$model = ids"
                        :limit-text="(count) => `${count} catégorie(s) sélectionnée(s)`"
                        :placeholderText="'Categories professionnelles'"
                    ></multiselect>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.categorie_professionnelle_ids.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="profession"
                    class="form-group"
                    v-if="customValidations.profession"
                    :class="{'form-group--error': $v.$dirty && $v.schema.profession.$invalid}"
                >
                    <label> Profession * </label>
                    <input
                        class="form-control"
                        placeholder="Profession"
                        autocomplete="disabled"
                        v-model="$v.schema.profession.$model"
                    >
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.profession.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
                <div
                    id="contrat_type_ids"
                    class="form-group"
                    v-bind:class="{'form-group--error': $v.$dirty && $v.schema.contrat_type_ids.$invalid}"
                    v-if="customValidations.contrat_type_ids"
                >
                    <label> Types de contrat *</label>
                    <multiselect
                        :value="$v.schema.contrat_type_ids.$model"
                        :options="formulaire.constantes.contratTypes"
                        :select-action="(ids) => $v.schema.contrat_type_ids.$model = ids"
                        :limit-text="(count) => `${count} contrat(s) sélectionné(s)`"
                        :placeholderText="'Types de contrat'"
                    ></multiselect>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.contrat_type_ids.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
                <div
                    id="employeur"
                    class="form-group"
                    v-if="customValidations.employeur"
                >
                    <label> Employeur </label>
                    <input
                        class="form-control"
                        placeholder="Employeur"
                        autocomplete="disabled"
                        v-model="$v.schema.employeur.$model"
                    >
                </div>
                <div
                    id="travail_lieu"
                    class="form-group"
                    v-if="customValidations.travail_lieu"
                >
                    <label> Lieu de travail </label>
                    <input
                        class="form-control"
                        placeholder="Lieu"
                        autocomplete="disabled"
                        v-model="$v.schema.travail_lieu.$model"
                    >
                </div>
                <div
                    id="travail_code_postal"
                    class="form-group"
                    v-if="customValidations.travail_code_postal"
                >
                    <label> Code postal du lieu de travail </label>
                    <input
                        class="form-control"
                        placeholder="Code postal"
                        v-model="$v.schema.travail_code_postal.$model"
                        autocomplete="disabled"
                    >
                </div>
                <div
                    id="embauche_date"
                    class="form-group"
                    v-if="customValidations.embauche_date"
                >
                    <label> Date d'embauche </label>
                    <input
                        class="form-control"
                        v-model="$v.schema.embauche_date.$model"
                        placeholder="Date d'embauche"
                    >
                </div>
                <div
                    id="periode_essai"
                    class="form-group"
                    v-if="customValidations.periode_essai"
                >
                    <label> Période d'essai terminée </label>

                    <toggle-button
                        :labels="{checked: 'Oui', unchecked: 'Non'}"
                        :sync="true"
                        :width="65"
                        :height="30"
                        v-model="$v.schema.periode_essai.$model"
                    />
                </div>
            </div>

            <div class="cadre-pied background-primary">
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <button
                    type="submit"
                    class="btn btn-transparent"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>
        </div>
    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'ProfessionnelForm',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        validations () {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, this.metadataCreer()) : {}
            }
        },
        data () {
            return {
                formulaire: null,
                schema: {}
            }
        },
        created () {
            this.$formulaire.charger('commun', 'Professionnel')
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    if (this.$route.params.id)
                        api.professionnelCharger({dossierId: this.$route.params.dossierId, emprunteurId: this.$route.params.id})
                            .then(d => {
                                this.schema = d.data
                                this.scrollTo(this.$route)
                            })
                })
        },
        computed: {
            customValidations() {
                return this.formulaire.customValidations(this.schema, this.metadataCreer())
            }
        },
        methods: {
            enregistrer (next = null) {
                return api.professionnelModifier({dossierId: this.$route.params.dossierId, emprunteur: this.$v.schema.$model})
                    .then(d => {
                        this.notificationAfficher().then(() => {
                            if (!next) {
                                this.menuContinuer()
                            }
                        })

                    })
            },
            metadataCreer(){
                return {
                    dossier: this.$dossier,
                    compte: this.$compte,
                    categoriesProfessionnelles: this.$utils.convertirTableau(this.formulaire.constantes.categoriesProfessionnelles, 'id')
                }
            }
        }
    }
</script>
