<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                Divers
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire">

                <div
                    id="devise_id"
                    v-if="$dossier && $dossier.multidevise"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.devise_id.$invalid}"
                >
                    <label> Devise * </label>
                    <select
                        class="form-control"
                        v-model.number="$v.schema.devise_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.devises"
                            :value="option.id"
                            v-html="option.lib"
                        >
                        </option>
                    </select>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.devise_id.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    class="form-cadre"
                    style="margin-top: 0"
                >

                    <div class="cadre-titre"> Impôts mensuels </div>

                    <div
                        id="endettement_impot_id"
                        class="form-group"
                        :class="{'form-group--error': $v.$dirty && $v.schema.endettement_impot_id.$invalid}"
                    >
                        <label>Impôt et endettement *</label>

                        <select
                            class="form-control"
                            v-model.number="$v.schema.endettement_impot_id.$model"
                        >
                            <option :value="null">Sélectionner</option>
                            <option v-for="option in formulaire.constantes.endettementTypes"
                                    :value="option.id"
                                    v-html="option.lib">
                            </option>
                        </select>
                        <div
                            class="form-error"
                            v-if="$v.$dirty && $v.schema.endettement_impot_id.$invalid"
                        >
                            Champs invalide
                        </div>
                    </div>
                    <div
                        id="impot_mensualite"
                        class="form-group"
                    >
                        <label>Montant</label>
                        <input
                            class="form-control"
                            placeholder="Montant"
                            v-model.number="$v.schema.impot_mensualite.$model"
                        />
                    </div>
                </div>

                <div
                    class="form-cadre"
                >

                    <div class="cadre-titre"> Loyer </div>

                    <div
                        class="form-group"
                    >
                        <label> Loyer maintenu après projet </label>
                        <toggle-button
                            :labels="{checked: 'Oui', unchecked: 'Non'}"
                            :sync="true"
                            :width="65"
                            :height="30"
                            v-model="$v.schema.loyer_maintenu.$model"
                        />
                    </div>
                    <div
                        id="loyer_mensualite"
                        class="form-group"
                    >
                        <label>Montant</label>
                        <input
                            class="form-control"
                            placeholder="Montant"
                            v-model.number="$v.schema.loyer_mensualite.$model"
                        />
                    </div>
                </div>

                <div
                    class="form-cadre"
                >

                    <div class="cadre-titre"> Pension alimentaire </div>

                    <div
                        class="form-group"
                    >
                        <div
                            id="loyer_maintenu"
                            class="form-group"
                        >
                            <label> Déduire des revenus (plus avantageux) </label>
                            <toggle-button
                                :labels="{checked: 'Oui', unchecked: 'Non'}"
                                :sync="true"
                                :width="65"
                                :height="30"
                                v-model="$v.schema.pension.$model"
                            />
                        </div>
                    </div>
                    <div
                        class="form-group"
                    >
                        <label>Montant</label>
                        <input
                            class="form-control"
                            placeholder="Montant"
                            v-model.number="$v.schema.pension_mensualite.$model"
                        />
                    </div>
                </div>

                <div
                    class="form-cadre"
                >

                    <div class="cadre-titre"> Autre charges </div>

                    <div
                        class="form-group"
                    >
                        <label>Montant</label>
                        <input
                            class="form-control"
                            placeholder="Montant"
                            v-model.number="$v.schema.autre_mensualite.$model"
                        />
                    </div>
                </div>

            </div>

            <div class="cadre-pied background-primary">
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <button
                    class="btn btn-transparent btn-save-next"
                    @click="e => soumettre(e, 'enregistrerEtRediriger')"
                >
                    Enregistrer +
                    <div class="etape-suivante">passer à l'étape suivante</div>
                </button>
                <button
                    class="btn btn-transparent"
                    @click="e => soumettre(e, 'enregistrer')"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>

            <transition
                name="notification-afficher">
                <notification
                    v-if="enregistrement.afficher"
                    :content="enregistrement.texte"
                    :type="'warning'"
                ></notification>
            </transition>
        </div>
    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'Divers',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        validations () {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, { dossier: this.$dossier }) : {}
            }
        },
        data () {
            return {
                formulaire: null,
                schema: {},
                destination: ''
            }
        },
        created () {
            const {dossierId} = this.$route.params

            this.$formulaire.charger('commun', 'Divers', { dossierId: dossierId })
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()

                    return api.chargesCharger({dossierId})
                        .then(d => {
                            this.schema = d.data
                            this.scrollTo(this.$route)
                        })
                })
        },
        methods: {
            soumettre(e, destination) {
                e.preventDefault()
                this.destination = destination
                this.valider(e)
            },
            enregistrer () {
                return api.chargesModifier({dossierId: this.$route.params.dossierId, charges: this.schema})
                    .then(() => {
                        this.notificationAfficher().then(() => {
                            this.menuContinuer(this.destination)
                            this.$root.$emit('revenusChargesRafraichir')
                        })
                    })
            }
        }
    }
</script>
