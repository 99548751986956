<template>
    <form
        @submit="valider"
        novalidate="true"
    >
        <div class="cadre content-cadre background-white">
            <div class="cadre-tete cadre-tete--menu">
                <span v-if="$dossier.professionnel">Associé</span>
                <span v-if="!$dossier.professionnel">Emprunteur</span>
                <formulaire-status
                    :form="$v"
                ></formulaire-status>
            </div>
            <div
                id="formulaire"
                class="cadre-content"
                v-if="formulaire"
            >
                <div
                    id="devise_id"
                    v-if="$dossier && $dossier.multidevise"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.devise_id.$invalid}"
                >
                    <label> Devise * </label>
                    <select
                        class="form-control"
                        v-model.number="$v.schema.devise_id.$model"
                    >
                        <option :value="null">Sélectionner</option>
                        <option
                            v-for="option in formulaire.constantes.devises"
                            :value="option.id"
                            v-html="option.lib"
                        >
                        </option>
                    </select>
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.devise_id.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="revenu_actuel"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.revenu_actuel.$invalid}"
                >
                    <label> Revenus mensuels actuels * </label>
                    <input
                        class="form-control"
                        placeholder="Montant"
                        v-model="$v.schema.revenu_actuel.$model"
                        v-float
                        autocomplete="disabled"
                        v-on:change="total_calculer"
                    >
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.revenu_actuel.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
                <div
                    id="revenu_mensuel"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.revenu_mois.$invalid}"
                >
                    <label> Nombre mois * </label>
                    <input
                        class="form-control"
                        placeholder="Nombre"
                        v-model.number="$v.schema.revenu_mois.$model"
                        autocomplete="disabled"
                        v-on:change="total_calculer"
                    >
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.revenu_mois.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
                <div
                    id="revenu_annuel"
                    class="form-group"
                >
                    <label> Revenus annuels nets professionnels </label>
                    <input
                        class="form-control disabled"
                        placeholder="Montant"
                        disabled
                        :value="revenuAnnuel"
                    >
                </div>
                <div
                    id="revenu_reference"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.revenu_reference.$invalid}"
                >
                    <label> Revenu fiscal de référence N-2 *</label>
                    <input
                        class="form-control"
                        placeholder="Montant"
                        autocomplete="disabled"
                        v-model="$v.schema.revenu_reference.$model"
                        v-float
                        v-on:change="total_calculer"
                    >
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.revenu_reference.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
                <div
                    id="revenu_allocations"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.revenu_allocations.$invalid}"
                >
                    <label> Allocations Familiales mensuelles * </label>
                    <input
                        class="form-control"
                        placeholder="Montant"
                        autocomplete="disabled"
                        v-model="$v.schema.revenu_allocations.$model"
                        v-float
                        v-on:change="total_calculer"
                    >
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.revenu_allocations.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
                <div
                    id="revenu_apl"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.revenu_apl.$invalid}"
                >
                    <label> Aide Personnalisée au Logement mensuelle * </label>
                    <input
                        class="form-control"
                        placeholder="Montant"
                        autocomplete="disabled"
                        v-model="$v.schema.revenu_apl.$model"
                        v-float
                        v-on:change="total_calculer"
                    >
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.revenu_apl.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
                <div
                    id="revenu_pension"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.revenu_pension.$invalid}"
                >
                    <label> Pension alimentaire mensuelle * </label>
                    <input
                        class="form-control"
                        placeholder="Montant"
                        autocomplete="disabled"
                        v-model="$v.schema.revenu_pension.$model"
                        v-float
                        v-on:change="total_calculer"
                    >
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.revenu_pension.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>
                <div
                    id="revenu_autre"
                    class="form-group"
                    :class="{'form-group--error': $v.$dirty && $v.schema.revenu_autre.$invalid}"
                >
                    <label> Autres revenus mensuels * </label>
                    <input
                        class="form-control"
                        placeholder="Montant"
                        autocomplete="disabled"
                        v-model="$v.schema.revenu_autre.$model"
                        v-float
                        v-on:change="total_calculer"
                    >
                    <div
                        class="form-error"
                        v-if="$v.$dirty && $v.schema.revenu_autre.$invalid"
                    >
                        Champ invalide
                    </div>
                </div>

                <div
                    id="revenu_total"
                    class="form-group"
                >
                    <label> Total </label>
                    <input
                        class="form-control disabled"
                        disabled
                        v-model.number="$v.schema.revenu_total.$model"
                    >
                </div>
            </div>

            <div class="cadre-pied background-primary">
                <button
                    type="button"
                    @click="formulaireAnnuler"
                    class="btn btn-transparent cancel-button"
                >
                    Annuler
                </button>
                <button
                    type="submit"
                    class="btn btn-transparent"
                >
                    Enregistrer
                </button>
            </div>

            <transition
                name="notification-afficher">

                <notification
                    v-if="notification.afficher"
                    :content="notification.texte"
                    :type="'success'"
                ></notification>
            </transition>
        </div>
    </form>
</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'

    export default {
        name: 'RevenuForm',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        beforeRouteUpdate(to, from, next){
            this.scrollTo(to)
            next()
        },
        validations () {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, { dossier: this.$dossier }) : {}
            }
        },
        computed: {
            revenuAnnuel(){
                if (!parseFloat(this.schema.revenu_actuel))
                    return 0;

                let res = parseFloat(this.schema.revenu_actuel) * parseInt(this.schema.revenu_mois)
                return (Math.round(res * 100) / 100)
            }
        },
        data () {
            return {
                formulaire: null,
                schema: {},
            }
        },
        created () {
            const {dossierId, id} = this.$route.params

            this.$formulaire.charger('commun', 'Revenu')
                .then((formulaire) => {
                    this.formulaire = formulaire
                    this.schema = formulaire.schema()
                })

            if (id)
                api.revenuCharger({dossierId, emprunteurId: id})
                    .then(d => {
                        this.schema = d.data
                        this.total_calculer()
                    })
        },
        methods: {
            total_calculer () {
                var res = 0;

                if (this.schema.revenu_actuel
                    && this.schema.revenu_mois)
                    res += parseFloat(this.schema.revenu_actuel) * parseInt(this.schema.revenu_mois) / 12;

                if (this.schema.revenu_allocations)
                    res += parseFloat(this.schema.revenu_allocations)

                if (this.schema.revenu_apl)
                    res += parseFloat(this.schema.revenu_apl)

                if (this.schema.revenu_pension)
                    res += parseFloat(this.schema.revenu_pension)

                if (this.schema.revenu_autre)
                    res += parseFloat(this.schema.revenu_autre)

                this.schema.revenu_total = (Math.round(res * 100) / 100);
            },
            enregistrer (e) {

                return api.revenuModifier({dossierId: this.$route.params.dossierId, emprunteur: this.schema})
                    .then(d => {
                        this.notificationAfficher().then(() => {
                            this.$root.$emit('revenusChargesRafraichir')
                            this.menuContinuer()
                        })
                    })
            },
        }
    }
</script>
