import api from '@/api'

export class Formulaire {

    constructor () {
        this.constantes = {}
        this.donnees = {}
    }

    charger (params) {
        return new Promise((resolve, reject) => {
            if (!this.meta || this.meta.length == 0)
                return resolve(this)

            const promises = []
            const champs = []

            for (var m of this.meta) {
                if (m.type === 'constantes'
                    && !m.recharger
                    && this[m.type][m.cle])
                    continue

                champs.push ({ type: m.type, cle : m.cle })
                promises.push(api[m.fonction](params))
            }

            if (promises.length == 0)
                return resolve(this)

            Promise.all(promises)
                .then((r) => {
                    /* Initialise les meta sur l'objet Formulaire */
                    for (let [i, m] of champs.entries())
                        this[m.type][m.cle] = r[i].data

                    resolve(this)
                })
                .catch(function (err) {
                    console.log(err)
                })
        })

    }
}
