<template>
    <div class="etape-container">

        <div class="etape-menu">
            <div
                class="cadre content-cadre background-gray">

                <div class="cadre-tete cadre-tete--menu">

                    <span>Sociétés</span>
                    <router-link
                        :to="$utils.formulaireAller('societes', 'creer')"
                        tag="div"
                        class="btn btn-ajouter"
                    >
                        <i class="fas fa-plus-circle"></i>
                        Ajouter une société
                    </router-link>
                </div>
                <div
                    id="menu"
                    class="cadre-content"
                >

                    <div
                        v-for="element in menu.menu"
                        :key="element.id"
                        class="menu-brique"
                    >

                        <router-link
                            tag="div"
                            :to="$utils.formulaireAller('societes', element.id)">

                            <a class="menu-brique-tete">
                                <div>
                                    <i class="far fa-user-circle avatar-icon"></i>
                                    {{ element.label }}
                                </div>
                                <i
                                    class="fas fa-trash"
                                    @click="(event) => afficherSuppressionConfirmation(event, element)"
                                ></i>
                            </a>
                            <div
                                class="menu-brique-contenu"
                                v-if="element.champs && element.champs.length"
                            >
                                <menu-kpis
                                    :kpis="element.champs"
                                    :base-route="$utils.formulaireAller('societes', element.id)"
                                ></menu-kpis>
                            </div>
                            <div class="menu-brique-pied"
                                v-if="element.meta && element.meta.requis">
                                <completion
                                    :element="element"
                                ></completion>
                            </div>
                        </router-link>

                    </div>
                    <div class="menu-brique brique-creer">
                        <router-link
                            :to="$utils.formulaireAller('societes', 'creer')"
                        >
                            <div>
                                <i class="far fa-user-circle avatar-icon"></i>
                                Nouvelle société
                            </div>
                        </router-link>
                    </div>

                </div>
            </div>
        </div>

        <div class="etape-content">
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view
                    :key="$route.path"
                    :menu-continuer="menuContinuer"
                ></router-view>
            </transition>
        </div>
        <transition
            name="fade"
            mode="out-in">
            <confirm
                v-if="elementASupprimer"
                status="ask-confirm"
                confirmText="Supprimer"
                :annulerClick="() => elementASupprimer = null"
                :confirmClick="() => supprimer()"
            >
                <div>
                    Souaitez-vous vraiment supprimer <span v-html="elementASupprimer.label"></span>?
                </div>
            </confirm>
        </transition>

    </div>
</template>

<script>
    import api from '@/api'
    import { menuMixin } from '@/utils/formulaire'

    export default {
        name: 'SocietesMenu',
        mixins: [
            menuMixin
        ],
        props: {
            navigation: Array
        },
        data() {
            return {
                elementASupprimer: null,
            }
        },
        methods: {
            charger() {
                const {dossierId} = this.$route.params
                return api.societesMenu({dossierId}).then(d => {
                    return d
                })
            },

            initialiser() {
                /* on redirige vers le formulaire edition si présent dans l'url */
                if (this.$route.params.id) {
                    this.$router.push(this.$utils.formulaireAller('societes', this.$route.params.id))
                    return
                }

                /* sinon on ouvre automatiquement un nouveau formulaire */
                this.$router.push(this.$utils.formulaireAller('societes', 'creer'))
            },
            supprimer() {
                api.societeSupprimer({dossierId: this.$route.params.dossierId, societeId: this.elementASupprimer.id})
                    .then(() => {
                        this.menuCharger()
                        this.elementASupprimer = null
                    })
            },
            afficherSuppressionConfirmation($event, elem) {
                $event.preventDefault()
                this.elementASupprimer = elem
            },
            menuContinuer(destination) {
                this.menuCharger().then(() => {
                    if (destination === 'enregistrerEtRediriger') {
                        this.$router.push(this.$utils.prochaineEtapeNavigationAller('societes', this.navigation))
                        return
                    }
                    this.$router.push(this.$utils.prochaineEtapeAller('societes', this.$route, this.navigation))
                })
            }
        }
    }

</script>

<style lang="scss" scoped>
    .edit-icon {
        margin-right: 0;
        margin-left: auto;
    }
</style>
