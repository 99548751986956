<template>
    <div>
        <ul class="kpis">
            <li
                class="kpi"
                v-for="kpi in kpis"
                :key="kpi.id"
            >
                <div
                    @click="routeAller(kpi.id)"
                >
                    <div>
                        <b>{{kpi.label}}</b>
                    </div>
                    <div v-html="kpi.valeur"></div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'MenuKPIs',
        props: {
            kpis: Array,
            baseRoute: Object
        },
        methods: {
            routeAller(id){
                const route = Object.assign({}, this.baseRoute, { query: { [id]: true }})
                this.$router.push(route)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/scss/colors";

    .kpis {
        list-style: none;
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 -10px 0;
        font-size: .8rem;
    }

    .kpi {
        width: 50%;
        margin-bottom: 10px;
        cursor: pointer;

        &:hover {
            color: $primary !important;
        }
    }
</style>
