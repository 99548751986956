import particulier from '@/formulaires/etapes/particulier'
import professionnel from '@/formulaires/etapes/professionnel'
import commun from '@/formulaires/etapes/commun'

export default function formulaireInstance(type, nom) {
    const types = {
        particulier,
        professionnel,
        commun
    }
    return new types[type][nom];
}
