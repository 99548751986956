<template>
    <div
        class="confirm"
        v-bind:class="status"
    >
        <div class="confirm-container">

            <div class="confirm-content">
                <i
                    class="icon-status"
                    v-bind:class="icon"></i>
                <slot></slot>
            </div>

            <div class="confirm-footer">
                <button
                    class="btn btn-transparent"
                    v-if="annulerClick"
                    @click="e => annulerClick(e)"
                >{{btnAnnulerText}}
                </button>
                <button
                    class="btn btn-transparent btn-ok"
                    @click="(e) => confirmClick(e, status === 'success')"
                >{{btnSuccessText}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Confirm',
        props: {
            status: String,
            confirmText: String,
            annulerText: String,
            annulerClick: Function,
            confirmClick: Function,
        },
        computed: {
            btnSuccessText () {
                return this.confirmText ? this.confirmText : this.status === 'success' ? 'Appliquer' : 'Recalculer'
            },
            btnAnnulerText () {
                return this.annulerText ? this.annulerText : 'Annuler'
            },
            icon () {
                if (this.status === 'ask-confirm') {
                    return 'fas fa-question-circle'
                }
                if (this.status === 'success') {
                    return 'fas fa-check-circle'
                }
                return 'fas fa-times-circle'
            }
        }
    }
</script>

<style lang="scss">

</style>
