// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
/* eslint-env node */
import Vue from 'vue'
import App from './App'
import router from './router'
import '@/assets/scss/main.scss'
import pace from 'pace-js-amd-fix/pace'
import Formulaire from '@/formulaires'
import Vuelidate, { validationMixin } from 'vuelidate'
import Datepicker from 'vuejs-datepicker'
import Notification from '@/components/Notification.vue'
import ToggleButton from 'vue-js-toggle-button/src/Button'
import Multiselect from '@/components/Multiselect'
import AutocompleteAdresse from '@/components/AutocompleteAdresse'
import Modal from '@/components/Modal.vue'
import Confirm from '@/components/Confirm.vue'
import TextEllipsis from '@/components/TextEllipsis.vue'
import Alerte from '@/components/Alerte.vue'
import FormulaireStatus from '@/components/FormulaireStatus.vue'
import VueNumeric from 'vue-numeric'
import { Compte, Dossier, Utils } from '@/utils'
import Completion from '@/components/Completion'
import MenuWarning from '@/components/MenuWarning'
import MenuKPIs from '@/components/MenuKPIs'
import '@/utils/directives'
import '@/utils/filters'
import AppDocument from './AppDocument'
import AppDocumentCommerciaux from './AppDocumentCommerciaux'


if (process.env.NODE_ENV === 'development') {
  require("@/assets/scss/_global.scss")
}

Vue.use(Formulaire)

Vue.use(Vuelidate)

Vue.mixin(validationMixin)

Vue.component('datepicker', Datepicker)

Vue.component('notification', Notification)

Vue.component('toggle-button', ToggleButton)

Vue.component('multiselect', Multiselect)

Vue.component('autocomplete-adresse', AutocompleteAdresse)

Vue.component('modal', Modal)

Vue.component('confirm', Confirm)

Vue.component('text-ellipsis', TextEllipsis)

Vue.component('alerte', Alerte)

Vue.component('formulaire-status', FormulaireStatus)


Vue.use(VueNumeric)

Vue.use(Utils)
Vue.use(Dossier)
Vue.use(Compte)


Vue.component('completion', Completion)

Vue.component('menu-warning', MenuWarning)

Vue.component('menu-kpis', MenuKPIs)

Vue.config.productionTip = false
pace.start({
  restartOnRequestAfter: 0,
  ajax: {
    trackMethods: ['GET', 'PUT', 'POST', 'DELETE']
  }
})

window.dossierClientApp = ({dossier_id, brick_div_id, token}) => {
  const btnReturns = document.querySelectorAll(`.btn_return`)
  const btnReturn = btnReturns[btnReturns.length - 1]
  if (brick_div_id) {
    const container = document.querySelector(`#${brick_div_id} .div_window`)
    if (container) {
      container.style.position = 'fixed'
      container.style.top = 0
      container.style.bottom = 0
      container.style.height = '100vh'
    }
    if (btnReturn) {
      btnReturn.style.display = 'none'
    }
  }
  Vue.prototype.$dossierId = dossier_id
  Vue.prototype.$token = token
  Vue.prototype.btnReturn = btnReturn

  /* Override mootools functions */
  var mtJSON = window.JSON;
  if (mtJSON.encode) {
    window.JSON = window.bkJSON;
    window.JSON.encode = mtJSON.encode;
  }

  return new Vue({
    el: '.dossier-client-app',
    router,
    components: {App},
    template: '<App />',
  })
}
window.documentClientApp = ({dossier_id,brick_div_id,token}) => {
  const btnReturns = document.querySelectorAll(`.btn_return`)
  const btnReturn = btnReturns[btnReturns.length - 1]
  if(brick_div_id){
    const container = document.querySelector(`#${brick_div_id} .div_window`)
    if (container) {
      container.style.position = 'fixed'
      container.style.top = 0
      container.style.bottom = 0
      container.style.height = '100vh'
    }
    if (btnReturn) {
      btnReturn.style.display = 'none'
    }
  }
  Vue.prototype.$dossierId = dossier_id
  Vue.prototype.$token = token
  Vue.prototype.btnReturn = btnReturn

  /* Override mootools functions */
  var mtJSON = window.JSON;
  if (mtJSON.encode) {
    window.JSON = window.bkJSON;
    window.JSON.encode = mtJSON.encode;
  } 
  var vue = new Vue({
    el: '.dossier-client-app',
    router,
    render: h => h(AppDocument),
    errorCaptured(error) {
      alert(error);
    },
    components: {AppDocument},
    template: '<App />',
   
  }) 
  return vue 
}

window.documentCommerciauxClientApp = ({dossier_id,brick_div_id,token}) => {
  const btnReturns = document.querySelectorAll(`.btn_return`)
  const btnReturn = btnReturns[btnReturns.length - 1]
  if(brick_div_id){
    const container = document.querySelector(`#${brick_div_id} .div_window`)
    if (container) {
      container.style.position = 'fixed'
      container.style.top = 0
      container.style.bottom = 0
      container.style.height = '100vh'
    }
    if (btnReturn) {
      btnReturn.style.display = 'none'
    }
  }
  Vue.prototype.$dossierId = dossier_id
  Vue.prototype.$token = token
  Vue.prototype.btnReturn = btnReturn

  /* Override mootools functions */
  var mtJSON = window.JSON;
  if (mtJSON.encode) {
    window.JSON = window.bkJSON;
    window.JSON.encode = mtJSON.encode;
  } 
  var vue = new Vue({
    el: '.dossier-client-app',
    router,
    render: h => h(AppDocumentCommerciaux),
    errorCaptured(error) {
      alert(error);
    },
    components: {AppDocumentCommerciaux},
    template: '<App />',
   
  }) 
  return vue 
}

if (process.env.NODE_ENV === 'development') {
  /**
   * Token utilisé: lambda1
   * Dossier part: 2169
   * Dossier pro: 2171
   */
  window.dossierClientApp({dossier_id: 4152, token: process.env.VUE_APP_DEFAULT_TOKEN})
}



