<template> 
    <div>
        <div class="align" v-if="isVisible()">
            <button v-if="!isDocCom" class="btn btn-file " >
                <i class='fa fa-upload icon-custom'></i>
                    <input 
                    type="file"
                    ref="file"
                    @change = "ajouterFichierDocumentVierge()"                 
                >           
            </button>
            <Transition name = fade>
                <div class="align" v-if="!(this.doc.fichier == '')">
                    <button class = "btn   btn-file " 
                            @click="telechargerFichier(doc.fichier_url)" 
                            >                   
                            <i class="fa fa-download icon-custom icons" ></i> 
                            
                    </button>
                    <button class = "btn   btn-file " 
                            @click="ouvrirWebViewer(doc.fichier_url,true)" 
                            >                   
                            <i class="fa fa-eye icon-custom icons" ></i> 
                            
                    </button>
                </div>
            </Transition>
        </div>
        <div class="stella"  v-if="!isVisible()">
            <div v-if="isUploadClient">
                <button v-if="!chargementImport" class = "btn  btn-file" >
                        Ajouter des documents
                        <input 
                        multiple
                        type="file"
                        ref="files"
                        @change = "ajouterNouveauDocumentAvecFichier()"                 
                        >                                              
                </button>  
                <div v-if="chargementImport" class="load">
                    <div class="loader"></div>
                    en cours d'importation
                </div>
            </div>
            <div v-if="!isUploadClient && !isDocCom  ">
                <div v-if="!click">
                    <div v-if="!openInput">
                        <button v-if="!chargementImport" 
                            class = "btn  btn-file stella  " 
                            @click = "clickevent()" >
                            Ajouter un document supplémentaire                                                                
                        </button>
                        <div v-if="chargementImport" class="load">
                            <div class="loader"></div>
                                en cours d'importation
                            </div>  
                        </div>
                    <div v-if = "openInput">
                        <button
                            class = "cross"
                             @click = "clickevent()" >
                            <i class='fa fa-times icon-custom-cross'></i>
                        </button>
                        Importer un fichier
                        <button  class="btn btn-file stella-bis  " >
                            <i class='fa fa-upload icon-custom'></i>
                                <input 
                                type="file"
                                ref="filebis"
                                @change = "ajouterNouveauDocumentAvecFichierPiece()"                 
                            >           
                        </button>
                    </div>          
                </div>
                <div  v-if="click">
                    <select class="select "  @change="onChange($event)" >
                        <option value="0">Choisir un nom de document:</option>
                        <option  v-for="piece in listePiece" :key="piece.id" :value="piece.id" >-{{piece.lib}}</option>
                    </select>
                </div>           
            </div>  
        </div>     
    </div>           
</template>

<script>
    import api from '@/api'
    export default { 
        name:'SimpleUpload',
        props:{
            doc:Object,
            show:Boolean,
            folderId:Number,
            isDocCom:Boolean,
            isUploadClient:Boolean
        },
        data(){
            return{
                file:"",
                files:[],
                publicPath: process.env.BASE_URL,
                chargementImport:false ,
                listePiece:[],
                click:false ,
                pieceId:0 ,
                openInput:false
            }
        }, 
        mounted(){
            const { dossierId } = this.$route.params
            api.listerPieceLib(dossierId).then((response)=>{
                    this.listePiece = response.data
            })      
        },       
        methods:{
            //méthode pour ajouter un fichier à un document existant en passant par l'api
            ajouterFichierDocumentVierge(){
                this.chargement(true,this.doc.id)
                this.file = "";
                this.file = this.$refs.file.files[0];
                const { dossierId } = this.$route.params
                const documentId = this.doc.id;
                const documentFolderId = this.doc.document_folder   
                api.fichierUploader(dossierId,documentId,documentFolderId,this.file,0).then((response) => { 
                    this.chargement(false,this.doc.id)             
                    this.doc.fichier = response.data.fichier.nom
                    this.doc.fichier_url = response.data["document"]["fichier_url"]
                    this.ouvrirWebViewer(this.doc.fichier_url,false )
                    this.doc.document_statut = response.data["document"]["document_statut"]                           
                    this.sendStatutFolder(response.data["list_folder"])                                        
                })
            },
            //méthode pour crée un document et associer le fichier à celui-ci
            ajouterNouveauDocumentAvecFichier(){ 
                this.files = [];
                this.files = this.$refs.files.files;   
                const { dossierId } = this.$route.params 
                let i = 0;
                while(i<this.files.length){
                    this.chargementImport=true;
                    api.fichierUploader(dossierId,0,this.folderId,this.files[i],0).then((response) => { 
                        this.chargementImport=false;                     
                        this.afficherDocument(response.data["document"])
                        this.sendStatutFolder(response.data["list_folder"])                                              
                    })   
                    i++
                }
                
            },
            ajouterNouveauDocumentAvecFichierPiece(){
                this.click = false;
                this.openInput= false;
                this.file = "";
                this.file = this.$refs.filebis.files[0];
                const { dossierId } = this.$route.params 
                this.chargementImport=true;
                api.fichierUploader(dossierId,0,this.folderId,this.file,this.pieceId).then((response) => {    
                    this.afficherDocument(response.data["document"])
                    this.sendStatutFolder(response.data["list_folder"])  
                    this.chargementImport=false;                                             
                })            
            },
            afficherDocument(document){
                this.$root.$emit('afficherDocument',document)              
            },
            sendStatutFolder(listFolder){
                this.$root.$emit('sendStatutFolder',listFolder)
            },
            isVisible(){
                return this.show
            },
            telechargerFichier(fichierUrl) {        
              window.open(fichierUrl);
            },
            ouvrirWebViewer(fichierUrl,afficher){
                this.$root.$emit('ouvrirWebViewer',fichierUrl,afficher)
            },
            chargement(chargement,docId){
                this.$root.$emit('chargement',chargement,docId)
            },
            clickevent(){
                this.click =!this.click
            },
            onChange(event) {
                this.clickevent()
                
                this.pieceId=event.srcElement.value
                this.openInput = true
            }
            
        }
    }
</script>

<style  lang="scss" scoped>
    @import "../assets/scss/_colors.scss";

    .stella{
        margin-top: 10px;
       display:flex;
        height: 60px !important;
    }
    .load{
        display: flex;
        text-align: center;
        
    }
    .loader {
        border: 11px solid #f3f3f3;
        border-radius: 50%;
        border-top: 11px solid #3498db;
        width: 39px;
        height: 39px;
        -webkit-animation: spin 1s linear infinite; /* Safari */
        animation: spin 1s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }

    .btn {
        min-width: 50px;
        margin-bottom: 5px;
        height: 46px !important;
        border-radius: 5px !important;
        padding: 0 20px;
        text-transform: none !important;
        color: $primary;
        cursor: pointer;
        transition: all 0.2s;
        }
    .btn:hover {
        background-color: $primary;
        color: aliceblue;
  
    }
    .btn-file {
        position: relative;
        overflow: hidden;
        border: 1px solid $primary;
        background: transparent;
        display: inline-block;
    }
    .btn-file input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 50px;
        margin-bottom: 5px;
        height: 40px;
       
        padding: 0 20px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        background: white;
        cursor: inherit;
        display: block;
        width: -webkit-fill-available;
        
    }
   
    .align{
       display:flex;
       justify-content: center;
       gap:7px;
      
    }
    .icon-custom{
        color: #305ab5;
        font-size: 20px;
       
       
    }
    .icon-custom-cross{
        color: #ca2722;
        font-size: 20px;
        border:none;
        margin-right: 10px;
       
       
    }
    .cross{
        border: none;
        background: transparent;
    }
     .icons:hover {
   
    color: aliceblue;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition:  all  1s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    
    }
    .select{
        // A reset of styles, including removing the default dropdown arrow
        // Additional resets for further consistency
        background-color: transparent;
       
        border-radius: 5px  !important;
        border: 5px solid #D5E8FA;
        padding: 0 1em 0 0;
        margin: 0;
        width: 285px !important;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;
    }
    select::-ms-expand {
    display: none;
    }
    .stella-bis{
        margin-left: 10px;
    }
    
    @media only screen and (max-width: 767px) {
        
        .loader{
            width: 24px;
            height: 22px;
        }
        
        
    }

</style>
