import { Dossier } from './Dossier';
import { Identite } from './Identite';
import { Banque } from './Banque';
import { Assurance, AssurancePrecisions } from './Assurance';
import { Bien, BienPrecisions } from './Bien';


export default {
    Dossier,
    Identite,
    Bien, BienPrecisions,
    Banque,
    Assurance, AssurancePrecisions,
}
