<template>
    <div class="modal">
        <form
            @submit="valider"
            novalidate="true"
        >
            <div class="modal-content">
                <div class="cadre">
                    <div class="cadre-tete cadre-tete--menu">
                        Calcul du prêt à l'accession sociale
                        <div
                            v-if="formulaire && $v.schema.pas_utiliser"
                            class="text-success"
                        >
                            <i class="fas fa-check-circle"></i> Prêt P.A.S activé
                        </div>
                        <div
                            v-if="formulaire && !$v.schema.pas_utiliser"
                            class="text-error"
                        >
                            <i class="fas fa-times-circle"></i> Prêt P.A.S désactivé
                        </div>
                    </div>
                    <div
                        id="formulaire"
                        class="cadre-content"
                        v-if="formulaire"
                    >

                        <div
                            class="form-group"
                            :class="{'form-group--error': $v.$dirty && $v.schema.projet_personnes.$error}"
                        >
                            <label>Nombre de personnes destinées à occuper le logement *</label>
                            <input
                                class="form-control"
                                placeholder="Nombre"
                                v-model.number="$v.schema.projet_personnes.$model"
                                autocomplete="disabled"
                            >
                            <div
                                class="form-error"
                                v-if="$v.$dirty && $v.schema.projet_personnes.$invalid"
                            >
                                Champ invalide
                            </div>
                        </div>
                        <div
                            class="form-group"
                        >
                            <label>Code postal</label>
                            <autocomplete-adresse
                                placeholderText="Code postal"
                                :value="$v.schema.projet_code_postal.$model"
                                searchKey="postal_code"
                                :selectAction="selectAction"
                                :inputAction="(v) => $v.schema.postal_code.$model = v"
                            ></autocomplete-adresse>
                        </div>
                        <div
                            class="form-group"
                            :class="{'form-group--error': $v.$dirty && $v.schema.projet_localite.$error}"
                        >
                            <label>Commune</label>
                            <autocomplete-adresse
                                placeholderText="Localité"
                                :value="$v.schema.projet_localite.$model"
                                searchKey="ville"
                                :selectAction="selectAction"
                                :inputAction="(v) => $v.schema.projet_localite.$model = v"
                            ></autocomplete-adresse>
                        </div>
                        <div
                            class="form-group"
                            :class="{'form-group--error': $v.$dirty && $v.schema.revenu_reference.$error}"
                        >
                            <label>Revenu fiscal de référence <b>N-2</b> *</label>
                            <input
                                class="form-control"
                                type="text"
                                placeholder="Montant"
                                v-model.number="$v.schema.revenu_reference.$model"
                                autocomplete="disabled"
                            >
                            <div
                                class="form-error"
                                v-if="$v.$dirty && $v.schema.revenu_reference.$invalid"
                            >
                                Champ invalide
                            </div>
                        </div>
                        <div
                            class="form-group"
                            :class="{'form-group--error': $v.$dirty && $v.schema.revenu_reference_conjoint.$error}"
                            v-if="customValidations.revenu_reference_conjoint"
                        >
                            <label>Revenu fiscal de référence <b>N-2</b> co-emprunteur *</label>
                            <input
                                class="form-control"
                                type="text"
                                placeholder="Montant"
                                v-model.number="$v.schema.revenu_reference_conjoint.$model"
                                autocomplete="disabled"
                            >
                            <div
                                class="form-error"
                                v-if="$v.$dirty && $v.schema.revenu_reference_conjoint.$invalid"
                            >
                                Champ invalide
                            </div>
                        </div>

                    </div>

                    <div class="cadre-pied background-primary">
                        <button
                            class="btn btn-transparent"
                            @click="annuler"
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            class="btn btn-transparent btn-pas"
                        >
                            Calculer
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <confirm
            status="success"
            confirmText="Utiliser"
            annulerText="Ne pas utiliser"
            v-if="confirmAfficher && pasDonnees.succes"
            :confirmClick="e => utiliserClick(e, true)"
            :annulerClick="e => utiliserClick(e, false)"
        >
            <div>
                Vous êtes éligible au Prêt à l'accession sociale
            </div>
        </confirm>
        <confirm
            status="error"
            confirmText="Recalculer"
            v-if="confirmAfficher && !pasDonnees.succes"
            :confirmClick="annulerClick"
            :annulerClick="appliquerClick"
        >
            <div>
                Vous n'êtes pas éligible au Prêt à l'accession social
            </div>
        </confirm>
    </div>

</template>

<script>
    import api from '@/api'
    import { formulaireMixin } from '@/utils/formulaire'
    import { validationMixin } from 'vuelidate'
    import Confirm from "../../../../components/Confirm.vue";

    export default {
        components: {
            Confirm
        },
        name: 'Pas',
        mixins: [
            formulaireMixin,
            validationMixin
        ],
        validations() {
            return {
                schema: this.formulaire ? this.formulaire.validations(this.schema, this.metadataCreer()) : {}
            }
        },
        props: {
            calculer: Function,
            annuler: Function
        },
        data() {
            return {
                formulaire: null,
                schema: {},
                pasDonnees: {},
                confirmAfficher: false,
            }
        },
        created() {
            this.init(this.$route.params.dossierId)
        },
        computed: {
            customValidations() {
                return this.formulaire.customValidations(this.schema, this.metadataCreer())
            },
        },
        methods: {
            init(dossierId) {
                this.$formulaire.charger('commun', 'PAS')
                    .then((formulaire) => {
                        this.formulaire = formulaire
                        this.schema = formulaire.schema()

                        api.pasCharger({dossierId}).then(d => {
                            this.schema = d.data
                        })
                    })
            },
            enregistrer() {
                api.pasCalculer({dossierId: this.$route.params.dossierId, pas: this.schema}).then((d) => {
                    this.pasDonnees = d.data
                    this.confirmAfficher = true
                })
            },
            metadataCreer() {
                return {
                    dossier: this.$dossier,
                }
            },
            annulerClick(e) {
                e.preventDefault()
                this.confirmAfficher = false
            },
            appliquerClick(e) {
                e.preventDefault()
                this.confirmAfficher = false
                this.calculer(this.pasDonnees)
            },
            utiliserClick(e, utiliser) {
                e.preventDefault()
                api.pasUtiliser({dossierId: this.$route.params.dossierId, pasUtiliser: {utiliser}}).then(() => {
                    this.confirmAfficher = false
                    this.calculer(this.pasDonnees)
                })
            },
            selectAction(localite) {
                this.$v.schema.projet_code_postal.$model = localite.postal_code
                this.$v.schema.projet_localite.$model = localite.ville
            }
        },
        watch: {
            'schema.projet_code_postal'(value) {
                if (value !== '' && value !== this.deviseModifie) {
                    this.deviseModifie = true
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../assets/scss/variables";

    .modal {
        .btn-pas {
            margin-left: auto;
            margin-right: 0;
        }

        .cadre-content {
            height: calc(100vh - #{$cadre-tete-height} - #{$cadre-pied-height} - 60px);

        }
        .long-checkbox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
                width: 80%;
            }
        }
    }
</style>
