<template>
    <!--<vue-multiselect
        ref="input"
        :placeholder="placeholder"
        open-direction="bottom"
        :value="value"
        :options="options"
        :multiple="false"
        :internal-search="false"
        :local-search="false"
        :show-no-options="false"
        :loading="isLoading"
        :clear-on-select="true"
        :class="{'no-value': placeholder === placeholderText}"
        @select="selectAction"
        selectLabel=""
        @search-change="asyncRechercher"
    >
        <template slot="noResult">Aucune ville trouvée</template>
        <template slot="singleLabel"></template>
        <template slot="option" slot-scope="props">
            <div class="option__desc">
                <span class="option__title" v-html="props.option.postal_code"></span> - <span class="option__title"
                                                                                              v-html="props.option.ville"></span>
            </div>
        </template>
    </vue-multiselect>-->
    <autocomplete
        ref="autocomplete"
        :onShouldGetData="asyncRechercher"
        url="0"
        :anchor="searchKey"
        :label="searchKey === 'ville' ? 'postal_code' : 'ville'"
        :debounce="500"
        :placeholder="placeholder"
        :classes="{ wrapper: 'form-wrapper', input: 'form-control' }"
        :on-select="selectAction"
        :on-input="inputAction"
    ></autocomplete>
</template>

<script>
    import api from '@/api'
    import Autocomplete from 'vue2-autocomplete-js'


    export default {
        name: 'AutocompleteAddress',
        components: {
          Autocomplete
        },
        props: {
            value: String,
            placeholderText: String,
            disabled: Boolean,
            selectAction: Function,
            searchKey: String,
            inputAction: Function
        },
        data() {
            return {
                options: [],
                isLoading: false,
            }
        },
        mounted(){
            this.$refs.autocomplete.$el.querySelector('input').setAttribute('autocomplete', 'disabled')
        },
        updated(){
            this.$refs.autocomplete.$el.querySelector('input').value = this.value
        },
        computed: {
            placeholder() {
                if (!this.value || this.value.length === 0) {
                    return this.placeholderText
                }
                return this.value
            }
        },
        methods: {
            asyncRechercher(v) {
                return api.adresseVilleChercher({[this.searchKey]: v}).then(d => d.data)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
