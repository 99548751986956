import { Formulaire } from '@/formulaires/Formulaire'
import { email, required } from 'vuelidate/lib/validators'

export class Coordonne extends Formulaire {

    meta = [
        {type: 'constantes', fonction: 'situationsImmobilieres', cle: 'situationsImmobilieres'},
    ]

    schema() {
        return {
            id: '',
            situation_immobiliere_id: null,
            habite_depuis: '',
            adresse: '',
            code_postal: '',
            ville: '',
            telephone_fixe: '',
            telephone_mobile: '',
            email: '',
            personne_morale: false
        }
    }

    validations(schema, {dossier}) {
        return {
            id: {},
            situation_immobiliere_id: {
                required: !schema.personne_morale ? required : false
            },
            habite_depuis: {},
            adresse: {
                required: !schema.personne_morale ? required : false
            },
            code_postal: {
                required: !schema.personne_morale ? required : false
            },
            ville: {
                required: !schema.personne_morale ? required : false
            },
            telephone_fixe: {},
            telephone_mobile: {},
            email: {email}
        }
    }
}

export class CoordonnePrecisions extends Formulaire {
    schema() {
        return {
            coordonnee_cacher: null,
            primo_accedants: null
        }
    }

    validations(schema) {
        return {
            coordonnee_cacher: {},
            primo_accedants: {}
        }
    }
}
